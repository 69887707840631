/*START TIPOGRAPFY*/
@import '../../shared/styles/default-vars-system.scss';

::ng-deep html,
body {
  font-family: $font-system;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #d6d6d6;
}

::-webkit-scrollbar-thumb {
  background-color: #949494;
  border-radius: 15px;
}

::ng-deep h1,
h2,
h3,
h4,
h5 {
  font-family: $font-system;
}

.text-muted {
  color: #a3a4a6 !important;

  small {
    font-size: 85%;
  }
}

.text-muted.text-tiny {
  font-size: 70%;
}

h4.font-weight-bold {
  font-size: 1.313rem;
}

::ng-deep .slideThickLabel {
  .mat-slide-toggle-content {
    font-weight: normal;
    text-transform: none;
  }
}

::ng-deep p,
a {
  font-family: $font-system;
}

.obrigatorio {
  color: #a00;
  font-size: 13px;
  font-weight: 700;
}

// Alert Dinamic
::ng-deep.ui-datatable-emptymessage {
  position: relative;

  span {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    padding: 2px 8px 2px 20px;
    border-radius: 4px;

    &:before {
      position: absolute;
      top: 4px;
      left: 15px;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f05a";
    }
  }
}

.alert-xs {
  position: relative;
  padding: 2px 5px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

/*start growl alert default system*/
::ng-deep.ui-growl {
  top: inherit;
  bottom: 25px;
  right: 35px;
  width: auto;
  min-width: 350px;
  max-width: 540px;
}

::ng-deep.ui-dialog {
  .ui-growl.ui-widget {
    border: 0px !important;
  }
}

/*warn*/
::ng-deep.ui-growl-item-container.ui-state-highlight.ui-growl-message-warn {
  background-color: #ffe9b5;
  border-color: #ffe9b5;
  color: #8a6714;
}

/*success*/
::ng-deep.ui-growl-item-container.ui-state-highlight.ui-growl-message-success {
  background-color: #b4f0b6;
  border-color: #b4f0b6;
  color: #2c832f;
}

/*error*/
::ng-deep.ui-growl-item-container.ui-state-highlight.ui-growl-message-error {
  background-color: #ffcbc8;
  border-color: #ffcbc8;
  color: #ab1a0f;
}

/*info*/
::ng-deep.ui-growl-item-container.ui-state-highlight.ui-growl-message-info {
  background-color: #bfe0fa;
  border-color: #bfe0fa;
  color: #1765a3;
}

/*end growl alert default system*/

/*BOX AND CARD PAGE SYSTEM*/
.mat-card {
  background-color: $bg-main;
  padding: 14px !important;
  font-family: $font-system;
}

.mat-card-title {
  font-size: 21px;
  color: $title-content;
}

.mat-card-subtitle {
  color: $sub-title-content;
}

.mat-card-actions,
.mat-card-content,
.mat-card-subtitle,
.mat-card-title {
  display: block;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: $font-system;
}

.margin-linnks {
  margin-left: 1%;
  margin-top: 5% !important;
  margin-right: 1%;
  margin-bottom: 2%;
}

::ng-deep .control-button {
  margin: 20px 30px 0 0 !important;
}

/*START NEW CARD SYSTEM*/
.shadow-box {
  display: flex;
  box-orient: vertical;
  box-direction: normal;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 0px 13px 0px rgba(62, 44, 90, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  padding: 1px;
  transition: border 500ms ease-out;

  .box-content {
    padding: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
  }
}

.header-box {
  position: relative;
  background-color: #fff;

  .header-box-content {
    padding: 0.7rem 1rem 0.4rem;

    .title-card {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 75%;
      font-size: 0.875rem;
      margin: 0;
      line-height: 1.8rem;
      color: #333;
      position: relative;
      font-weight: 600;

      span {
        color: #999;
        font-style: italic;
        font-weight: 500;
        padding-left: 2px;
      }

      i {
        padding-right: 5px;
        color: #0091ea;
      }
    }

    .actions-card {
      position: absolute;
      right: 10px;
      top: 10px;

      .btn {
        padding: inherit !important;

        span {
          color: #999999;
          font-size: 32px;
          line-height: 24px;
          vertical-align: middle;
          width: 28px;
        }

        &:hover {
          span {
            color: #999999;
          }

          text-decoration: none;
        }

        .full-icon {
          font-size: 24px;
        }

        .excel,
        .pdf {
          font-size: 18px;
          margin-right: -2px;
          margin-top: 2px;
          color: #999999;
        }

        .print-icon {
          font-size: 21px;
          position: relative;
          top: 1px;
          margin-right: 2px;
        }
      }

      .btn.btn-info {
        padding: 0.25rem 0.8rem !important;
      }

      .btn.btn-full {
        margin-right: -10px;
      }

      .btn-group {
        .btn {
          padding: 0.25rem 0.8rem !important;
        }
      }
    }
  }
}

::ng-deep.box-fullscreen {
  position: fixed !important;
  z-index: 2055;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh !important;
  max-height: 100vh !important;
  width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 0;
  border: 0;

  .box-content {
    overflow-y: auto;
  }

  .cal-month-view {
    .cal-day-cell {
      min-height: 125px !important;
    }
  }
}

/*END NEW CARD SYSTEM*/

.box-content {
  position: relative;
  background-color: #fff;
  padding: 25px;
}

.div-card-total {
  font-family: $font-system;
  font-size: 12px;
  font-weight: 400;
  width: auto !important;
  min-width: 250px;
  height: 80px;
  background-color: #fff;

  .titulo-card-total {
    font-family: $font-system;
    font-weight: 700;
    margin-bottom: 0;
    padding: 10px 20px;
    text-transform: uppercase;
  }

  .valor-card-total {
    color: $vl-total;
    margin: 5px 20px;
    font-size: 20px;
  }
}

.div-card-total.div-card-total.card-total-radius {
  box-shadow: 0px 0px 13px 0px rgba(62, 44, 90, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 13px;
  min-width: auto;
  height: auto;
}

/*Card Total Nota*/
.div-card-total-nota {
  padding: 5px;

  .titulo-card-total-nota {
    color: #666;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #ccc;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 6px -2px #ccc;
    box-shadow: 0 0 6px -2px #ccc;
    margin-bottom: 0;

    span {
      color: $vl-total;
      padding-left: 2px;
    }
  }
}

/*START TITLES*/
.title-page {
  font-size: 15px !important;
  font-weight: 700;
  color: $title-page-color;
  margin: 10px 0px 8px;
  text-transform: uppercase;
}

.sub-title-page {
  color: $sub-title-page-color;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}

.sub-title {
  border-bottom: 1px solid $sub-title-border-color;
  padding-bottom: 4px;
  color: $sub-title-color;
  font-weight: 600;
  font-size: 13px;
  margin: 10px 0px 10px;
  text-transform: uppercase;

  i {
    color: $sub-title-icon-color;
  }
}

.sub-title.font-xs {
  font-size: 14px;
}

.card-header-title {
  i {
    color: $sub-title-icon-color;
  }

  .link-action {
    text-decoration: underline;
    font-size: 8pt;
    color: #aaa;
    font-weight: 600;

    &:hover {
      text-decoration: none;
      color: #666666;
    }
  }
}

/*END TITLES*/

.obrigatorio {
  color: #a00;
  font-size: 13px;
  font-weight: 700;
}

/*END TIPOGRAPFY*/

/*START CLEARFIX ANGULAR 12*/
::ng-deep {
  .clearfix {
    display: block;
    clear: both;

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
}

/*END CLEARFIX ANGULAR 12*/

/*START FORMS*/

/*START INPUT PRIME ANGULAR 12*/
::ng-deep.p-inputtext {
  font-size: 0.75rem;
  border: 1px solid #cccccc;
  padding: 0.4rem 0.429rem;
  display: block;
  width: 100%;

  &:hover {
    border-color: $accent-color !important;
  }
}

/*START BTN GROUP FILTER CARD*/
.btn-group-custom {
  .btn {
    color: #333333;
    border-radius: 10px;
    font-weight: 520;

    &:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: #0074dd !important;
      color: #fff;
    }
  }

  .btn.active {
    background-color: #0074dd !important;
    color: #fff;
    cursor: default;
  }

  .dropdown-item {
    font-size: 12px;
    outline: none;
    cursor: pointer;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: #0074dd;
  }
}

/*END BTN GROUP FILTER CARD*/

.btn-link {
  color: $btn-link-color;

  &:hover {
    color: $btn-link-color-hover;
    text-decoration: underline;
  }

  .excel {
    color: $btn-link-color-excel;
  }
}

.btn {
  text-transform: uppercase;
  font-family: $font-system;
  font-size: 0.7rem;
  font-weight: 500;
  padding: 0.375rem 1.3rem;

  &:active:focus {
    outline: none;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none !important;
    color: #555;
  }
}

::ng-deep.p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: #cccccc;
}

::ng-deep .slideThickLabel {
  .mat-slide-toggle-content {
    font-weight: normal;
    text-transform: none;
  }
}

/*END INPUT PRIME ANGULAR 12*/

/*START DROPDOWN ANGULAR 12*/
::ng-deep.p-dropdown,
::ng-deep.p-multiselect {
  display: flex !important;
  border-color: #cccccc;

  .p-dropdown-label,
  .p-multiselect-label {
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  .p-dropdown-trigger,
  .p-multiselect-trigger {
    color: $accent-color;
    border-color: none;
    width: auto;
    padding: 0 5px;
    .p-multiselect-trigger-icon {
      font-size: 0.7rem;
    }
  }

  .p-dropdown-label {
    text-align: left;
    text-transform: uppercase;
  }
}

.p-dropdown-panel,
.p-multiselect-panel {

  .p-dropdown-items,
  .p-multiselect-items {

    .p-dropdown-empty-message,
    .p-multiselect-empty-message {
      font-size: 0.75rem;
    }

    .p-dropdown-item,
    .p-multiselect-item {
      padding: 0.329rem 0.857rem !important;
      font-size: 0.75rem;
    }

    .p-dropdown-item.p-highlight,
    .p-multiselect-item.p-highlight {
      background: $accent-color !important;
    }
  }

  .p-dropdown-header .p-dropdown-filter,
  .p-multiselect-header .p-multiselect-filter {
    height: 30px;
    &:hover {
      border-color: $accent-color !important;
    }
  }
}

/*diferença multiselect*/
.p-multiselect-panel {
  .p-multiselect-header {
    .p-multiselect-close:enabled:hover {
      color: red !important;
    }
  }

  .p-multiselect-items {
    .p-multiselect-item {
      margin: 2px 0 !important;

      &:focus {
        box-shadow: none !important;
      }
    }

    .p-dropdown-item.p-highlight,
    .p-multiselect-item.p-highlight {
      background: #eeeeee !important;
      color: #333333 !important;
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}




.btn.btn-sm {
  padding: 0.25rem 0.8rem;
  font-size: 0.7rem;
  text-transform: inherit;
}

.btn.btn-xs {
  padding: 0.15rem 0.5rem !important;
  font-size: 0.675rem !important;
}

.btn.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #333;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-primary {
  background-color: $btn-primary-bg;
  border-color: $btn-primary-border;

  &:hover {
    background-color: $btn-primary-bg-hover;
    border-color: $btn-primary-border-hover;
  }

  &:focus,
  &:active {
    background-color: $btn-primary-bg-focus;
    border-color: $btn-primary-border-focus;
  }
}

.btn-danger {
  background-color: $btn-danger-bg;
  border-color: $btn-danger-border;

  &:hover {
    background-color: $btn-danger-bg-hover;
    border-color: $btn-danger-border-hover;
  }

  &:focus,
  &:active {
    background-color: $btn-danger-bg-focus;
    border-color: $btn-danger-border-focus;
  }
}

.btn-secondary {
  background-color: $btn-secondary-bg;
  border-color: $btn-secondary-bg;
  color: #ffffff;

  &:hover {
    background-color: $btn-secondary-bg-hover;
    border-color: $btn-secondary-bg-hover;
    color: #ffffff;
  }

  &:focus,
  &:active {
    background-color: $btn-secondary-bg-focus;
    border-color: $btn-secondary-bg-focus;
    color: #ffffff;
    outline: none;
  }
}

.btn-info {
  background-color: $btn-info-bg;
  border-color: $btn-info-border;
  color: $btn-info-color;

  &:hover {
    background-color: $btn-info-bg-hover;
    border-color: $btn-info-border;
    color: $btn-info-color-hover;
  }

  &:focus,
  &:active {
    background-color: $btn-info-bg-focus;
    border-color: $btn-info-border !important;
    color: $btn-info-color-hover !important;
  }

  &:disabled {
    background-color: #eee;
    border-color: #eee;
    color: #333;
  }
}

.btn-close-times {
  i {
    font-size: 18px;
    color: #999999;
    padding: 4px 0;

    &:hover {
      color: #666666;
    }
  }
}

.btn-outline-primary {
  color: $btn-outline-primary-color;
  border-color: $btn-outline-primary-color;

  &:focus,
  &:active,
  &:hover {
    color: #ffffff;
    border-color: $btn-outline-primary-color !important;
    background-color: $btn-outline-primary-color !important;
  }
}

.back-card {
  margin-right: 2px;
  vertical-align: middle;

  .btn {
    padding: 0 !important;

    span.material-icons {
      line-height: 1;
      vertical-align: middle;
      font-size: 20px;
    }
  }
}

/*TABLES*/

::ng-deep.p-dropdown:not(.p-disabled),
::ng-deep.p-multiselect:not(.p-disabled) {
  &:hover {
    border-color: $accent-color;
  }
}

::ng-deep.p-dropdown:not(.p-disabled).p-focus,
::ng-deep.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: $accent-color;
}

::ng-deep.p-checkbox .p-checkbox-box.p-highlight {
  background: $accent-color;
  border-color: $accent-color;
  outline: none;
}

::ng-deep.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 0.2rem $accent-opacity-05;
  border-color: $accent-color;
}

::ng-deep.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight {
  &:hover {
    background: $accent-color;
    border-color: $accent-color;
  }
}

::ng-deep.ui-chkbox-box.ui-state-active {
  background: $accent-color;
  border-color: $accent-color;
  color: #ffffff;
  outline: none;
}


.text-overflow-dynamic-ellipsis {
  position: absolute;
  white-space: nowrap;
  overflow-y: visible;
  overflow-x: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  top: 0;
  left: 0;
}

.text-overflow-dynamic-container:after,
.text-overflow-dynamic-ellipsis:after {
  content: '-';
  display: inline;
  visibility: hidden;
  // width: 0;
}

/*End Ellipsis Dinamic Table (data-table-component)*/

/*END DROPDOWN ANGULAR 12*/

/* START MAT SELECT */
::ng-deep.mat-select-panel {
  padding: 0.4em !important;
  font-size: 0.66rem !important;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.3) !important;

  .mat-option.mat-selected:not(.mat-option-multiple) {
    background-color: #00bfa5 !important;
    border-color: #00bfa5;
    color: #fff;
    border-radius: 0.25rem;
  }

  .mat-option {
    height: 2em !important;
    padding: 0.4em;
    border-radius: 4px;
    font-size: 0.75rem !important;
  }
}

::ng-deep.mat-option.mat-active {
  background: #00bfa5 !important;
  color: #fff !important;
}

::ng-deep.mat-select {
  display: block !important;
  border-radius: 3px;

  .mat-select-trigger {
    display: block;
    border: 1px solid #ccc;
    border-radius: 3px;
    height: auto;
    line-height: 1.2rem;
  }

  .mat-select-value {
    padding: 5px 7px;
    font-size: 0.75rem !important;

    .mat-select-value-text {
      font-size: 0.75rem !important;
      color: #495057;
    }
  }

  .mat-select-arrow-wrapper {
    border-left: 1px solid #ccc;
    min-width: 30px;

    .mat-select-arrow {
      color: #00bfa5;
      margin: 0 10px;
    }
  }
}

::ng-deep.ui-widget {
  font-family: $font-system;
}

::ng-deep.ui-paginator {
  .ui-dropdown {
    border-radius: 3px;

    label.ui-dropdown-label {
      padding: 2px 20px 1px 0px !important;
    }
  }

  .ui-dropdown.ui-state-focus {
    border: 1px solid $ui-dropdown-border-hover;
  }
}

/* END MAT SELECT */

::ng-deep.mat-form-field {
  display: inherit !important;
}

// DISABLED MAT-SELECT DROPDOWN
::ng-deep.mat-select-disabled {
  opacity: 0.7;
  background-image: none;

  .mat-select-trigger {
    background-color: transparent;
    border-color: #ededed;
    color: #222;
    cursor: not-allowed !important;
    border: 1px solid #ccc;
  }
}

// END MAT SELECT

::ng-deep.ui-widget:disabled {
  opacity: 0.7;
  background-image: none;
  background-color: transparent;
  color: #222;
  border: 1px solid #d6d6d6;
  cursor: not-allowed !important;
}

.table-fixed tbody td,
.table-fixed tbody th,
.table-fixed thead>tr>th {
  float: left;
  position: relative;
}

/*START SLIDE TOOGLE*/
::ng-deep.mat-slide-toggle.mat-disabled {
  .mat-slide-toggle-label {
    opacity: 0.7;
  }
}

::ng-deep.mat-disabled {
  .mat-slide-toggle-bar {
    opacity: 0.7;
  }
}

/*END SLIDE TOOGLE*/

::ng-deep input:disabled::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #444;
}

::ng-deep input:disabled:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #444;
}

::ng-deep input:disabled::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #444;
}

::ng-deep input:disabled:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #444;
}

.form-control-sm {
  padding: 0.15rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem;
}

.form-control[readonly] {
  background-color: #fcfcfc;
  border-color: #ededed;
  color: #555555;
  cursor: not-allowed;
}

label {
  font-family: $font-system;
  font-weight: bold;
  color: $label;
  font-size: 11px;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::ng-deep.ui-dropdown.ui-state-disabled {
  .ui-dropdown-label {
    cursor: not-allowed !important;
  }
}

/*MAT INPUT FORM*/
::ng-deep.container-formulario {
  .mat-select {
    width: 100% !important;
  }

  .ui-datatable-data>tr>td.ui-datatable-emptymessage {
    span {
      color: #004085;
      background-color: #cce5ff;
      border-color: #b8daff;
      padding: 2px 8px 2px 20px;
      border-radius: 3px;
      position: relative;

      &:before {
        color: #004085;
        position: absolute;
        top: 2px;
        left: 6px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f05a";
      }
    }
  }
}

/*linha efeito input mat*/
::ng-deep.mat-form-field-underline,
::ng-deep.mat-form-field-subscript-wrapper {
  display: none;
}

/*input mat*/
::ng-deep.mat-input-element {
  border: 1px solid #ccc !important;
  padding: 0.15rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem;
  width: 100% !important;
  height: 30px;
  outline: none !important;

  &:hover {
    border-color: $form-control-border-hover !important;
  }
}

/*description input*/
::ng-deep.mat-hint {
  font-size: 9px !important;
  text-align: right;
  display: block;
  margin-top: 2px;
}

/*MAT-SLIDER*/
::ng-deep.mat-accent .mat-slider-track-fill,
::ng-deep.mat-accent .mat-slider-thumb,
::ng-deep.mat-accent .mat-slider-thumb-label,
::ng-deep.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb {
  background-color: $mat-slide-toggle-thumb;
}

/*CHECKBOX*/
::ng-deep.mat-checkbox-layout {
  margin-bottom: 0;

  .mat-checkbox-inner-container {
    height: 20px !important;
    width: 20px !important;
  }

  .mat-checkbox-label {
    font-family: $font-system;
    text-transform: uppercase;
    font-weight: bold;
    color: #555;
    font-size: 12px;
  }
}

::ng-deep.mat-checkbox {
  font-family: $font-system;
}

::ng-deep.mat-checkbox-frame {
  transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1) !important;
  border-width: 2px !important;
  border-radius: 2px !important;
}

::ng-deep mat-checkbox.mat-checkbox-checked.mat-accent {
  .mat-checkbox-background {
    background-color: $mat-checkbox-background !important;
  }
}

::ng-deep.mat-checkbox-checked {
  .mat-checkbox-background {
    background-color: $mat-checkbox-background !important;
  }
}

::ng-deep.mat-checkbox-indeterminate.mat-accent,
.mat-checkbox-checked.mat-accent {

  .mat-checkbox-background,
  .mat-checkbox-background {
    background-color: $mat-checkbox-background !important;
  }
}

::ng-deep.ui-chkbox {
  .ui-chkbox-icon {
    display: inline-block;
  }

  .ui-datatable {

    th.ui-state-default,
    .ui-datatable-data>tr>td {
      background: none;
      border-width: 0;
      padding: 6px 10px !important;
      font-family: $font-system;
    }
  }

  .ui-datatable {
    .ui-datatable-data>tr>td {
      border-width: 0;
      padding: 6px 10px !important;
      font-size: 12px;
      font-family: $font-system;

      .edit-line {
        display: block;
        cursor: pointer;

        i {
          display: none;
          color: #00bfa5;
        }

        &:hover {
          i {
            display: inline;
          }
        }
      }
    }
  }

  .ui-datatable {
    .ui-datatable-data>tr>td.ui-datatable-emptymessage {
      span {
        color: #004085;
        background-color: #cce5ff;
        border-color: #b8daff;
        padding: 5px 8px 5px 20px;
        border-radius: 3px;
        position: relative;

        &:before {
          color: #004085;
          position: absolute;
          top: 5px;
          left: 6px;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f05a";
        }
      }
    }
  }

  .ui-widget-content {
    border: none !important;
    outline: none;
  }

  .ui-datatable {

    tbody.ui-datatable-hoverable-rows>tr.ui-widget-content,
    tbody>tr.ui-widget-content {
      &:hover {
        background: $table-td-hover;
        font-weight: bold;
      }
    }
  }

  .ui-datatable tbody>tr.ui-widget-content &:hover {
    background: $table-td-hover;
    font-weight: bold;
  }
}

::ng-deep.check-all {
  text-align: left;
  padding: 6px 5.3px;
  position: relative;
  top: 10px;
}

::ng-deep.mat-checkbox-disabled {
  opacity: 0.7;
}

/*RADIO*/
::ng-deep.mat-radio-button {
  font-size: $font-system;
}

::ng-deep.mat-radio-label {
  font-size: $font-system;
  text-transform: uppercase;
  color: #555;
  font-weight: bold;
  font-size: 12px;
}

::ng-deep.mat-radio-label-content {
  font-size: 12px;
}

::ng-deep.mat-radio-button.mat-accent {
  .mat-radio-inner-circle {
    background-color: $mat-radio-background !important;
  }
}

::ng-deep.mat-radio-button.mat-accent.mat-radio-checked {
  .mat-radio-outer-circle {
    border-color: #333 !important;
  }
}

::ng-deep.radio-normal.mat-radio-button {
  .mat-radio-label-content {
    font-weight: normal;
    text-transform: initial;
  }
}

/*START CALENDAR*/
::ng-deep.type-p-calendar {
  position: relative;
  width: 100%;
  display: block;

  &::before {
    color: #116fbf;
    position: absolute;
    top: 4px;
    left: 10px;
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    font-weight: 900;
    content: "\f073";
    z-index: 10;
  }

  .ui-calendar input {
    text-align: right;
  }
}

::ng-deep.p-datepicker {
  padding: 0.25rem;

  &:not(.p-disabled) {
    table {
      tr {
        span {
          &:not(.p-highlight) {
            &:not(.p-disabled) {
              &:hover {
                color: #00bfa5;
              }
            }
          }
        }
      }
    }
  }

  table {
    font-size: 0.9rem;
    text-align: center;

    td,
    th {
      padding: 0.2rem;
    }

    td>span {
      width: 2rem;
      height: 2rem;
    }

    td>span.p-highlight {
      background-color: #00bfa5;
    }
  }
}

::ng-deep.p-calendar {
  .p-inputtext {
    text-align: right;
  }

  .p-datepicker {
    padding: 0.25rem;

    &:not(.p-disabled) {
      table {
        tr {
          span {
            &:not(.p-highlight) {
              &:not(.p-disabled) {
                &:hover {
                  color: #00bfa5;
                }
              }
            }
          }
        }
      }
    }
  }

  /* START BTN ACTIONS FADE*/
  .action-fade {
    position: absolute;
    right: -30px;
    transition: 0.4s;
    transition-timing-function: ease-in-out;
    opacity: 0;
    border: 1px solid #666;
    border-radius: 3px;
    padding: 4px 6px;
    color: #444;
    margin-top: -2px;

    &:hover {
      color: #999;
    }
  }

  .ui-datatable tbody>tr.ui-widget-content {
    &:hover {
      .action-fade {
        line-height: 0;
        right: 5px;
        opacity: 1;
        border-color: #999;
      }

      .action-fade.icon-one {
        right: 66px;
      }

      .action-fade.icon-two {
        right: 35px;
      }

      .action-fade.icon-tree {
        right: 5px;
      }
    }
  }

  /* END BTN ACTIONS FADE*/

  .ui-datatable {
    .ui-sortable-column-icon {
      color: $table-sortable !important;
    }
  }

  .ui-datatable {
    .ui-datatable-thead>tr>th {
      font-weight: bold;
      font-size: 12px;


    }

    table {
      font-size: 0.9rem;
      text-align: center;

      td,
      th {
        padding: 0.2rem;
      }

      td>span {
        width: 2rem;
        height: 2rem;
      }

      td>span.p-highlight {
        background-color: #00bfa5;
      }
    }
  }
}

::ng-deep.p-datepicker-header {
  padding: 0.25rem;

  .p-datepicker-prev {
    &:hover {
      color: #00bfa5;
    }
  }

  .p-datepicker-next {
    &:hover {
      color: #00bfa5;
    }
  }
}

/*END CALENDAR*/

/*MAT SLIDE TOGGLE*/
::ng-deep.mat-slide-toggle-content {
  font-family: "Open Sans", sans-serif !important;
  text-transform: uppercase;
  font-weight: 700;
  color: #555;
  font-size: 12px;
}

::ng-deep.mat-slide-toggle.mat-checked {
  &:not(.mat-disabled) {
    .mat-slide-toggle-thumb {
      background-color: $mat-slide-toggle-thumb;
    }
  }
}

::ng-deep.mat-slide-toggle.mat-checked {
  &:not(.mat-disabled) {
    .mat-slide-toggle-bar {
      background-color: $mat-slide-toggle-bar;
    }
  }

  .ui-datatable tbody>tr.ui-widget-content.ui-state-highlight {
    background: $table-state-highlight-bg !important;
    font-weight: bold;
    color: #ffffff !important;

    td {
      span {
        color: #ffffff !important;
      }
    }

    .add-text {
      color: #ffffff !important;

      .btn-link {
        color: #ffffff !important;
      }
    }

    .action-fade {
      border-color: #fff !important;
    }

    &:hover {
      background: $table-state-highlight-bg-hover !important;
    }

    i {
      color: #ffffff !important;
    }
  }

  .ui-datatable {
    tfoot {
      td.ui-state-default {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}

::ng-deep.mat-slide-toggle.mat-disabled,
.mat-slide-toggle.mat-disabled {

  .mat-slide-toggle-label,
  .mat-slide-toggle-thumb-container {
    cursor: not-allowed !important;
  }
}

::ng-deep.mat-slide-toggle.slide-toggle-normal {
  .mat-slide-toggle-content {
    font-weight: normal;
    text-transform: initial;
  }
}

/*Blocked Campos User*/
::ng-deep .lks-form-grid-readOnly {
  .locked-element {
    cursor: not-allowed;
  }

  .div-card-total-nota {
    opacity: 0.7 !important;
    pointer-events: none;
  }

  .fr-toolbar {
    .fr-command.fr-btn {
      opacity: 0.7 !important;
      pointer-events: none;
    }
  }

  .fr-box.fr-basic {
    .fr-element {
      opacity: 0.7 !important;
      pointer-events: none;
    }
  }

  .sub-title {
    opacity: 0.7 !important;
    pointer-events: none;
  }

  .vl-total {
    opacity: 0.7 !important;
    pointer-events: none;
  }

  .lks-p-table {
    opacity: 0.7;
    pointer-events: none;
  }

  .lks-form-grid {
    label {
      opacity: 0.7;
    }
  }

  .botao-adicionar {
    pointer-events: none;
    opacity: 0.7;
  }

  input,
  textarea {
    pointer-events: none;
    opacity: 0.7;
    border-color: #d6d6d6 !important;
    color: #222;
  }

  p-dropdown {
    pointer-events: none;
    opacity: 0.7;
  }

  ::ng-deep.ui-dropdown {
    pointer-events: none;
  }

  p-calendar {
    pointer-events: none;

    &:before {
      opacity: 0.7;
    }
  }
}

::ng-deep.no-overflow-x {
  .p-datatable-scrollable-body {
    overflow-x: hidden;
  }
}

/*Prime table Filter Border*/
::ng-deep .table-filter-border {
  .ui-datatable {
    th.ui-sortable-column {
      &:hover {
        background: none;
        border-color: none;
      }
    }

    .ui-column-filter {
      padding: 0.3em 0.5em !important;
    }
  }

  .ui-datatable {

    th.ui-state-default,
    .ui-datatable-data>tr>td {
      background: none;
      border-width: 0;
      padding: 6px 10px !important;
      font-size: 11px;
      font-family: $font-system;
      border: 1px solid #cccccc;

      /*PRIME SELECT TBODY TABLE*/
      .type-p-dropdown {
        .ui-dropdown {
          width: 100% !important;
          outline: none;

          label.ui-dropdown-label {
            line-height: .85rem !important;
            font-size: .64rem !important;
          }
        }
      }

      .mat-radio-button {
        .mat-radio-label {
          margin-bottom: 0px;
        }
      }
    }
  }

  .ui-datatable {
    .ui-datatable-data>tr>td {
      border-width: 0;
      padding: 6px 10px !important;
      font-size: 11px;
      font-family: $font-system;
      border: 1px solid #cccccc;

      .edit-line {
        display: block;
        cursor: pointer;

        i {
          display: none;
          color: #00bfa5;
        }

        &:hover {
          i {
            display: inline;
          }
        }
      }
    }
  }

  .ui-datatable {
    .ui-datatable-data>tr>td.ui-datatable-emptymessage {
      span {
        color: #004085;
        background-color: #cce5ff;
        border-color: #b8daff;
        padding: 3px 8px 3px 20px;
        font-weight: 550;
        border-radius: 3px;
        position: relative;

        &:before {
          color: #004085;
          position: absolute;
          top: 3px;
          left: 6px;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f05a";
        }
      }
    }
  }

  .ui-widget-content {
    border: none !important;
    outline: none;
  }

  .ui-datatable {

    tbody.ui-datatable-hoverable-rows>tr.ui-widget-content,
    tbody>tr.ui-widget-content {
      &:hover {
        background: $table-td-hover;
        font-weight: bold;
      }
    }
  }

  .ui-datatable tbody>tr.ui-widget-content &:hover {
    background: $table-td-hover;
    font-weight: bold;
  }
}

/* START BTN ACTIONS FADE*/
.action-fade {
  position: absolute;
  right: -30px;
  transition: 0.4s;
  transition-timing-function: ease-in-out;
  opacity: 0;
  border: 1px solid #666;
  border-radius: 3px;
  padding: 4px 6px;
  color: #444;
  margin-top: -2px;

  &:hover {
    color: #999;
  }
}

.ui-datatable tbody>tr.ui-widget-content {
  &:hover {
    .action-fade {
      line-height: 0;
      right: 5px;
      opacity: 1;
      border-color: #999;
    }

    .action-fade.icon-one {
      right: 66px;
    }

    .action-fade.icon-two {
      right: 35px;
    }

    .action-fade.icon-tree {
      right: 5px;
    }
  }
}

/* END BTN ACTIONS FADE*/

.ui-datatable {
  .ui-sortable-column-icon {
    color: $table-sortable !important;
  }

}

::ng-deep .ui-calendar {
  pointer-events: none;
}

mat-select {
  pointer-events: none;
  opacity: 0.7;
}

::ng-deep .mat-select {
  pointer-events: none;
  opacity: 0.7;
}

mat-checkbox {
  pointer-events: none;
  opacity: 0.7;
}

::ng-deep .mat-checkbox {
  pointer-events: none;
  opacity: 0.7;
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #444;
}

input::-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #444;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #444;
}

input::-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #444;
}

::ng-deep.ui-state-disabled,
::ng-deep.ui-state-disabled {
  opacity: 1;
  background-image: none;
  background-color: transparent;

  .ui-datatable {
    .ui-datatable-thead>tr>th {
      font-weight: bold;
      font-size: 11px;
      padding: 0.5em 0.4em;
      background-color: #efefef;
      border: 1px solid #cccccc;
      border-bottom: 0;

      &:hover {
        background-color: #efefef;
      }


      .check-all {
        padding: 0;
        text-align: center;
      }

      .type-p-mult {
        margin-top: 0.25em;
        display: block;

        .ui-multiselect {
          .ui-multiselect-label {
            display: block;
            width: 100%;
            padding-top: 7px;
            padding-bottom: 6px;
            height: 26px !important;
            white-space: nowrap;
            overflow: hidden;
          }

          .ui-multiselect-trigger {
            .fa {
              margin-top: 0.75em;
            }
          }
        }
      }
    }
  }

  .ui-datatable tbody>tr.ui-widget-content.ui-state-highlight {
    background: $table-state-highlight-bg !important;
    font-weight: bold;
    color: #ffffff;

    td {
      span {
        color: #ffffff !important;
      }
    }

    .add-text {
      color: #ffffff !important;

      .btn-link {
        color: #ffffff !important;
      }
    }

    .action-fade {
      border-color: #fff !important;
    }

    &:hover {
      background: $table-state-highlight-bg-hover !important;
    }

    i {
      color: #ffffff !important;
    }
  }

  .ui-datatable {
    tfoot {
      .ui-state-default {
        border-top: 0;
      }

      td.ui-state-default {
        font-size: 12px;
        font-weight: bold;
        padding: 0.5em 0.4em;
        border-top: 0;
      }
    }
  }
}

::ng-deep.ui-state-disabled,
::ng-deep.ui-state-disabled {
  opacity: 0.7;
}

// start froola
::ng-deep.fr-box {
  position: relative;
}

::ng-deep.fr-toolbar.fr-desktop.fr-top.fr-basic {
  border: 1px solid #ededed;
  border-bottom: 0;
}

::ng-deep.fr-toolbar.fr-top {
  top: 0;
  border: 1px solid #ededed;
  border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1px 3px rgb(255, 255, 255), 0 1px 1px 1px rgb(255, 255, 255);
  box-shadow: 0 1px 3px rgb(255, 255, 255), 0 1px 1px 1px rgb(255, 255, 255);
}

::ng-deep.fr-toolbar {
  color: #222;
  background: #fff;
  border: 1px solid #ededed;
  font-family: $font-system;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 0;
  border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1px 3px rgb(255, 255, 255), 0 1px 1px 1px rgb(255, 255, 255);
  box-shadow: 0 1px 3px rgb(255, 255, 255), 0 1px 1px 1px rgb(255, 255, 255);
  border: 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .fr-newline {
    display: none;
  }

  .align-down-table {
    position: relative;
    right: -15px;
    padding-right: 5px;
    font-size: 0.7rem;

    .btn {
      font-size: 0.7rem;
      padding-right: 0;
    }
  }
}

::ng-deep.fr-box.fr-basic.fr-top .fr-wrapper {
  border-top: 0;
  border: 1px solid #ededed;
  border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1px 3px rgb(255, 255, 255), 0 1px 1px 1px rgb(255, 255, 255);
  box-shadow: 0 1px 3px rgb(255, 255, 255), 0 1px 1px 1px rgb(255, 255, 255);
}

::ng-deep.fr-element img {
  cursor: pointer;
  margin: inherit !important;
}

.class1 {
  border-radius: 10%;
  border: 2px solid #efefef;
}

.class2 {
  opacity: 0.5;
}

::ng-deep.fr-second-toolbar {
  display: none;
}

/*Table Card*/
.card-table {
  thead {
    tr {
      th {
        color: #4D5259;
        text-transform: uppercase;
      }
    }
  }
}

// end froala

/*END FORMS*/


/*START ALERTS*/
.alert-xs {
  position: relative;
  padding: 2px 5px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

/*table border Bootstrap*/
.table.table-border-boot {
  th {
    background: $table-border-head-bg;
    border: 1px solid #a4a4a4;
    border-bottom: 1px solid #a4a4a4 !important;
    font-size: 12px;
    font-family: $font-system;
    text-transform: uppercase;
    padding: 6px 10px;
    font-weight: bold;

    strong {
      font-weight: 600;
    }
  }

  tr {
    &:hover {
      td {
        background: $table-td-hover;
        font-weight: bold;
      }
    }
  }

  td {
    background: inherit;
    font-size: 12px;
    vertical-align: middle;
    padding: 4px 10px;
    font-family: $font-system;
    border: 1px solid #a4a4a4;

    strong {
      font-weight: 600;
    }

    .delete {
      i {
        color: #a52632;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #dd2c00;
        }
      }
    }

    .edit {
      i {
        color: #2c9856;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #00bfa5;
        }
      }
    }

    .attachment,
    .shared {
      i {
        color: #116fbf;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #0091ea;
        }
      }
    }

    .formula {
      i {
        color: #953dac;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #be4bdb;
        }
      }
    }
  }
}

.alert.alert-xs {
  padding: 1px 6px;
  font-size: 11px;
}

/*END ALERTS*/

/*START CARDS SYSTEM*/

/*START BOX AND CARD PAGE SYSTEM*/
.mat-card {
  background-color: $bg-main;
  padding: 14px !important;
  font-family: $font-system;
}

.mat-card-title {
  font-size: 21px;
  color: $title-content;
}

.mat-card-subtitle {
  color: $sub-title-content;
}

.table-striped {
  tbody {
    tr:nth-of-type(even) {
      background-color: #fafafb;
    }
  }
}

.mat-card-actions,
.mat-card-content,
.mat-card-subtitle,
.mat-card-title {
  display: block;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: $font-system;
}

.margin-linnks {
  margin-left: 1%;
  margin-top: 5% !important;
  margin-right: 1%;
  margin-bottom: 2%;
}

::ng-deep .control-button {
  margin: 20px 30px 10px 0 !important;
  height: 34px !important;

  .class-remove-button,
  .class-first-button,
  .class-edit-button,
  .class-new-button {
    min-height: 34px !important;
    height: 34px !important;

    &:disabled {
      min-height: 34px !important;
    }
  }

  .class-first-button {
    margin: 0 3px !important;
  }

  .class-edit-button {
    min-height: 33px !important;
    margin: 0 3px !important;

    .material-icons {
      vertical-align: baseline;
    }
  }

  .class-new-button {
    &:last-of-type {
      line-height: 34px;
      margin-left: 6px !important;
    }
  }

  .class-remove-button {
    margin: 0 3px !important;
  }

  .class-other-button {
    margin: 0 3px !important;
  }
}

/*Prime table border*/
::ng-deep.table-border {
  .ui-table.ui-table-hoverable-rows {
    .ui-table-tbody>tr:not(.ui-state-highlight) {
      &:hover {
        background: $table-td-hover;
        font-weight: bold;
      }
    }
  }

  .ui-table {
    .ui-table-thead>tr>th {
      background: $table-border-head-bg !important;
      border-color: #a4a4a4;
      color: black !important;
      font-size: 11px;
      font-family: $font-system;
      text-transform: uppercase;
      padding: 6px 10px !important;
      font-weight: bold;

      .ui-state-highlight {
        background: $table-border-head-bg !important;
        color: black !important;
      }

      p-sorticon {
        color: #00BFA5 !important;
      }
    }

    .ui-table-tfoot>tr>td {
      background: #efefef !important;
      border: 1px solid #ccc;
      font-size: 11px !important;
      font-family: "Open Sans", sans-serif !important;
      font-weight: bold;
    }

    .ui-table-tbody>tr {
      td .action-fade {
        position: absolute;
        right: -30px;
        transition: 0.4s;
        transition-timing-function: ease-in-out;
        opacity: 0;
        border: 1px solid #444;
        border-radius: 3px;
        padding: 4px 6px;
        color: #444;
        margin-top: -2px;
      }

      &:hover {
        td .action-fade {
          line-height: 0;
          right: 5px;
          opacity: 1;

          &:hover {
            color: #999;
            border-color: #999;
          }
        }

        background: $table-td-hover;
        font-weight: bold;
      }
    }

    .ui-table-tbody>tr>td,
    .ui-datatable .ui-datatable-data>tr>td {
      background: inherit;
      font-size: 11px;
      padding: 6px 10px !important;
      font-family: $font-system;
      border-color: #ccc;

      .edit-line {
        display: block;
        min-height: 20px;
        cursor: pointer;

        i {
          display: none;
          color: #00bfa5;
        }

        &:hover {
          i {
            display: inline;
          }
        }
      }
    }

    .ui-table.ui-table-hoverable-rows {
      .ui-table-tbody>tr:hover {
        &:not(.ui-state-highlight) {
          &:hover {
            background: $table-state-highlight-bg-hover !important;
            color: #fff !important;
          }
        }
      }
    }

    .ui-table-tbody>tr.ui-state-highlight {
      background-color: $table-state-highlight-bg !important;

      &:hover {
        font-weight: normal;
      }

      .edit,
      .delete {
        i {
          color: #ffffff !important;
        }
      }
    }
  }

  .edit-line {
    display: block;
    cursor: pointer;

    i {
      display: none;
      color: #00bfa5;
    }

    &:hover {
      i {
        display: inline;
      }
    }
  }

  .ui-datatable tbody>tr.ui-widget-content {
    &:hover {
      background: $table-td-hover;
      font-weight: bold;
    }
  }

}

/*END BOX AND CARD PAGE SYSTEM*/

/*START NEW CARD SYSTEM*/
.shadow-box {
  display: flex;
  box-orient: vertical;
  box-direction: normal;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 0px 13px 0px rgba(62, 44, 90, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  padding: 1px;
  transition: border 500ms ease-out;

  .box-content {
    padding: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
  }
}

.header-box {
  position: relative;
  background-color: #fff;

  .header-box-content {
    padding: 0.7rem 1rem 0.4rem;

    .title-card {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 75%;
      font-size: 0.875rem;
      margin: 0;
      line-height: 1.8rem;
      color: #333;
      position: relative;
      font-weight: 600;

      span {
        color: #999;
        font-style: italic;
        font-weight: 500;
        padding-left: 2px;
      }

      i {
        padding-right: 5px;
        color: #0091ea;
      }
    }

    .actions-card {
      position: absolute;
      right: 10px;
      top: 10px;

      .btn {
        padding: inherit !important;

        span {
          color: #999999;
          font-size: 22px;
          line-height: 26px;
          vertical-align: middle;
          width: auto;
        }

        &:last-child {
          span {
            font-size: 32px;
          }
        }

        &:hover {
          span {
            color: #999999;
          }
        }
      }
    }
  }
}


.table-border,
.table-filter,
.table-no-filter,
.table.table-borderless {
  tr>td {
    .delete {
      i {
        color: #a52632;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #dd2c00;
        }
      }
    }

    .edit {
      i {
        color: #2c9856;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #00bfa5;
        }
      }
    }

    .attachment,
    .shared {
      i {
        color: #116fbf;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #0091ea;
        }
      }
    }

    .formula {
      i {
        color: #953dac;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #be4bdb;
        }
      }
    }

    .default {
      i {
        color: #555;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #888;
        }
      }
    }
  }
}

::ng-deep.table-filter,
::ng-deep.table-filter-border {
  .ui-datatable {
    tbody>tr.ui-widget-content {
      td {
        .edit-line {
          display: block;
          min-height: 20px;
          cursor: pointer;

          i {
            display: none;
            color: #00bfa5;
          }
        }

        .add-text {
          display: none;
        }
      }

      &:hover {
        td {
          .edit-line {
            i {
              display: inline;
              color: #00bfa5;

            }
          }

          .add-text {
            display: block;
          }
        }
      }
    }

    .ui-datatable-data>tr>td {
      .ui-cell-data {
        .delete {
          i {
            color: #a52632;
            cursor: pointer;
            padding: 0 3px;

            &:hover {
              color: #dd2c00;
            }
          }

          text-decoration: none;
        }

        .full-icon {
          font-size: 24px !important;
        }

        .excel,
        .pdf {
          font-size: 18px;
          margin-right: -2px;
          margin-top: 2px;
          color: #999999;
        }

        .print-icon {
          font-size: 21px;
          position: relative;
          top: 1px;
          margin-right: 2px;
        }
      }

      .btn.btn-info {
        padding: 0.25rem 0.8rem !important;
      }

      .btn.btn-full {
        margin-right: -10px;
      }

      .btn-group {
        .btn {
          padding: 0.25rem 0.8rem !important;
        }
      }
    }
  }
}

::ng-deep.box-fullscreen {
  position: fixed !important;
  z-index: 2055;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh !important;
  max-height: 100vh !important;
  width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 0;
  border: 0;

  .box-content {
    overflow-y: auto;
  }

  .cal-month-view {
    .cal-day-cell {
      min-height: 125px !important;

      .edit {
        i {
          color: #2c9856;
          cursor: pointer;
          padding: 0 3px;

          &:hover {
            color: #00bfa5;
          }
        }
      }

      .attachment,
      .shared {
        i {
          color: #116fbf;
          cursor: pointer;
          padding: 0 3px;

          &:hover {
            color: #0091ea;
          }
        }
      }

      .default {
        i {
          color: #888;
          cursor: pointer;
          padding: 0 3px;

          &:hover {
            color: #999;
          }
        }
      }
    }
  }
}

// Ajusta a view da diretiva 'sortable';
::ng-deep.ui-datatable th.ng-star-inserted.ui-state-default.ui-unselectable-text.ui-sortable-column.ui-state-active {
  color: #000000;
  outline: none !important;
}

/*Table Soft*/
.table-soft {
  thead {
    tr {
      th {
        font-size: 12px;
        font-family: $font-system;
        font-weight: bold;
        padding: 5px 8px;
        background-color: #d8e1ea;
        border-color: #bbb;
        border-bottom: 0;
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 11px;
        font-family: $font-system;
        padding: 5px 8px;
        border-color: #ddd;
        border-top-color: #bbb;
      }
    }
  }
}

/*TREETABLE PRIMENG*/
::ng-deep.table-tree {
  th.ui-state-default {
    font-size: 12px;
    background-color: $table-tree-head-bg;
    text-overflow: ellipsis;
  }

  .ui-treetable {
    tbody {
      td {
        &:first-child {
          span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .ui-treetable-toggler {
      color: $table-tree-toggler;
      font-size: 16px;
      float: left;
      margin-top: 3px;
    }
  }

  .ui-treetable tbody .ui-treetable-row {
    td {
      position: relative;
      font-size: 11px;
      text-overflow: ellipsis;
      padding: 0;

      >div {
        &:hover {
          font-weight: bold;
        }

        padding: .25em .5em;
        min-height: 23px;
      }

      >div.nivel1 {
        background-color: #f6f6f6;
        font-weight: bold;
        color: #555555;
      }

      .comments {
        cursor: pointer;

        &::before {
          font-family: "Font Awesome 5 Free";
          content: "\f0d9";
          font-weight: 900;
          position: absolute;
          right: -2px;
          top: -9px;
          transform: rotate(-222deg);
          font-size: 14px;
          color: darkred;
        }
      }
    }
  }
}

/*END NEW CARD SYSTEM*/

/*START CARD GERAL*/
.box-content {
  position: relative;
  background-color: #fff;
  padding: 25px;
}

/*END CARD GERAL*/

/*START CARD TOTAL*/
.div-card-total {
  font-family: $font-system;
  font-size: 12px;
  font-weight: 400;
  width: auto !important;
  min-width: 250px;
  height: 80px;
  background-color: #fff;

  .titulo-card-total {
    font-family: $font-system;
    font-weight: 700;
    margin-bottom: 0;
    padding: 10px 20px;
    text-transform: uppercase;
  }

  .valor-card-total {
    color: $vl-total;
    margin: 5px 20px;
    font-size: 20px;
  }
}

.div-card-total.div-card-total.card-total-radius {
  box-shadow: 0px 0px 13px 0px rgba(62, 44, 90, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 13px;
  min-width: auto;
  height: auto;
}

/*END CARD TOTAL*/

/*START CARD TOTAL NOTA*/
.div-card-total-nota {
  padding: 5px;

  .titulo-card-total-nota {
    color: #666;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #ccc;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 6px -2px #ccc;
    box-shadow: 0 0 6px -2px #ccc;
    margin-bottom: 0;

    span {
      color: $vl-total;
      padding-left: 2px;
    }
  }
}

/*END CARD TOTAL NOTA*/

/*CARDS GRID REPORT*/
.card-deck.card-custom {
  .card {
    &:hover {
      transition: 0.2s;
      box-shadow: 0px 0px 11px -6px #9c9c9c;
    }

    .card-body {
      padding: 1.5rem 0.75rem;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;

      .icon-card {
        display: inline-table;
        background: #eeeeee;
        cursor: pointer;
        border-radius: 100%;
        width: 45px;
        height: 45px;
        margin-right: 8px;

        i {
          text-align: center;
          display: block;
          color: #4d5259;
          font-size: 22px;
          padding: 12px;
          transition: 0.2s;
        }

        &:hover {
          i {
            color: #00bfa5;
          }
        }
      }

      .info-card {
        display: inline-table;
      }

      .card-title {
        display: inline-flex;
        margin-bottom: 1px;
        font-size: 0.85rem;
        font-weight: bold;
        color: #666;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          color: #00bfa5;
        }
      }

      .card-text {
        display: flex;
        font-size: 0.80rem;
        width: auto !important;

        .text-muted {
          .date {
            color: #777777;
            text-decoration: underline;

            i {
              color: #0091ea;
            }
          }
        }
      }
    }
  }
}

::ng-deep.ui-paginator {
  a {
    font-size: 13px;
  }

  .ui-paginator-page {
    padding: .125em .575em;
  }

  .ui-paginator-page.ui-state-active,
  .ui-state-highlight {
    background: $paginator-bg;
    color: $paginator-color;
    border-color: $paginator-border;
  }
}

::ng-deep.ui-datatable {
  .ui-paginator-bottom {
    .ui-dropdown {
      .ui-dropdown-panel {
        .ui-dropdown-item {
          font-size: 0.7em !important;
        }
      }

      .ui-dropdown-trigger {
        border-left: 1px solid #d6d6d6 !important;

        .fa {
          margin-top: 0px;
        }
      }
    }

    margin-bottom: 1.2rem;
    min-width: 245px;
    max-width: 245px;
    border: 0;
  }
}

@media (max-width: 1600px) {
  .card-deck.card-custom {
    .card {
      min-width: 240px;
      max-width: 245px;
      flex:0;
    }
  }
}

.card-custom {
  .card {
    min-width: 240px;
    max-width: 245px;
    flex:0;
  }
}

/*CARD DASHBOARD*/
.card-outline-primary,
.card-outline-primary-colorgrey {
  background-color: #ffffff;
  border: 2px solid #0074dd;
  border-radius: 10px;
  color: #777;
  padding: 4px;
  margin: 0px;
}

::ng-deep.ui-table,
::ng-deep.ui-datatable {
  .ui-paginator-bottom {
    margin-top: 15px;
  }
}

/*Correcao paginator overflow*/

/*Yes Overflow Tabs*/
::ng-deep.overflow-tabs {
  .mat-tab-body {
    .mat-tab-body-content {
      overflow-x: auto !important;
    }
  }

  .mat-tab-body.mat-tab-body-active {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  .mat-tab-body-wrapper {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}

/*Not Overflow Tabs*/
::ng-deep.mat-tab-body-content,
::ng-deep.mat-tab-body.mat-tab-body-active,
::ng-deep.mat-tab-body-wrapper {
  overflow: initial !important;
}

::ng-deep.mat-tab-body {
  .mat-tab-body-content {
    overflow: initial !important;
  }
}

/*START MATERIAL TABS*/
.mat-tab-labels {
  display: flex;
  background-color: transparent;
}

.mat-tab-label {
  font-family: $font-system;
  transition: all 0.2s;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    background-color: #0074dd;
    color: #ffffff;

    .percentenge {
      .status-percentenge {
        color: #ffffff !important;
      }
    }
  }

  .title-card {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: normal;
    min-height: 30px;
  }

  .value {
    font-weight: bold;
    font-size: 1.1rem;
  }

  .percentenge {
    text-align: right;
    display: block;
    font-size: 0.8rem;
    margin-bottom: 6px;

    .status-percentenge {
      font-size: 16px;
      vertical-align: middle;
      position: relative;
      top: -2px;
    }

    .status-percentenge.positive {
      color: green;
    }

    .status-percentenge.negative {
      color: darkred;
    }
  }

  .info-secondary {
    .info-1 {
      display: block;
      font-size: 13px;
      line-height: 5px;
    }

    .info-2 {
      font-size: 12px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      opacity: .6;
      min-width: 120px !important;
      text-align: center;
      display: inline-flex !important;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      white-space: nowrap;
      position: relative;
      border: #d0d0d0 0.8px;
      border-style: solid;
    }
  }
}

.mat-tab-label-active {
  font-weight: 500;
  color: #4D5259;
  opacity: 1 !important;
  background-color: #FFF !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #00BFA5;
  height: 1px !important;
}

.print {
  position: absolute;
  top: 0;
  display: none;
}

.ui-datatable {
  tr.bold {
    font-weight: bold;
  }
}

.mat-tab {
  box-shadow: 0 0 0 0;
}

/*END MATERIAL TABS*/

// MAT SELECT
::ng-deep.mat-select-panel {
  padding: 0.4em !important;
  font-size: 0.66rem !important;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.3) !important;

  .mat-option.mat-selected:not(.mat-option-multiple) {
    background-color: #00bfa5 !important;
    border-color: #00bfa5;
    color: #fff;
    border-radius: 0.25rem;
  }

  .mat-option {
    height: 2em !important;
    padding: 0.4em;
    border-radius: 4px;
    font-size: 0.75rem !important;
  }
}

::ng-deep.mat-option.mat-active {
  background: #00bfa5 !important;
  color: #fff !important;
}

::ng-deep.mat-select {
  display: block !important;
  border-radius: 3px;

  .mat-select-trigger {
    display: block;
    border: 1px solid #ccc;
    border-radius: 3px;
    height: auto;
    line-height: 1.2rem;
  }

  .mat-select-value {
    padding: 5px 7px;
    font-size: 0.75rem !important;

    .mat-select-value-text {
      font-size: 0.75rem !important;
      color: #495057;
    }
  }

  .mat-select-arrow-wrapper {
    border-left: 1px solid #ccc;

    .mat-select-arrow {
      color: #00bfa5;
    }
  }
}

::ng-deep table {
  thead {
    tr>th {
      .mat-select {
        border: none !important;
      }
    }
  }
}

.card-outline-primary {
  min-height: 94px;
}

.card-outline-primary.active {
  background-color: #0074dd;
  color: #ffffff;

  .status-percentenge {
    color: #ffffff !important;
  }
}

.card-outline-primary-colorgrey {
  border: 1px solid;

  &:hover {
    background-color: transparent;
    color: inherit;
    box-shadow: 0 0 8px 1px #dddddd;
  }

  .title-card {
    font-weight: normal;
  }
}

::ng-deep.mat-form-field {
  display: inherit !important;
}

// DISABLED MAT-SELECT DROPDOWN
::ng-deep.mat-select-disabled {
  opacity: 0.7;
  background-image: none;

  .mat-select-trigger {
    background-color: transparent;
    border-color: #ededed;
    color: #222;
    cursor: not-allowed !important;
    border: 1px solid #ccc;
  }
}

::ng-deep.ui-widget:disabled {
  opacity: 0.7;
  background-image: none;
  background-color: transparent;
  color: #222;
  border: 1px solid #d6d6d6;
  cursor: not-allowed !important;
}

::ng-deep.mat-slide-toggle.mat-disabled {
  .mat-slide-toggle-label {
    opacity: 0.7;
  }
}

::ng-deep.mat-disabled {
  .mat-slide-toggle-bar {
    opacity: 0.7;
  }
}

/*PRIME SELECT*/
::ng-deep.type-p-dropdown {
  display: block;

  .ui-dropdown {
    width: 100% !important;
    outline: none;
  }

  .ui-widget-content {
    background: #ffffff;
    color: #222222;
  }

  .ui-shadow {
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  }

  .ui-corner-all {
    border-radius: 3px;
  }

  .ui-dropdown.ui-state-focus {
    border: 1px solid $ui-dropdown-border-hover;
  }

  .percentenge {
    text-align: center;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.card-primary {
  background-color: #0074dd;
  border-radius: 10px;
  padding: 4px;
  margin: 0px;
  min-height: 75px;
  font-family: $font-system;
  transition: all 0.2s;
  color: #ffffff;

  >div {
    border-right: 1px solid;

    &:last-child {
      border-right: none;
    }
  }

  .info {
    .align-badge {
      position: absolute;
      top: -7px;
      margin-left: -15px;
      font-size: 10px;
      border-radius: 50%;
      min-width: 18px;
      min-height: 17px;
      padding: 3px;
      text-align: center;
    }

    small {
      font-size: 70%;
    }

    h6 {
      font-weight: bold;
      font-size: 0.8rem;
    }
  }
}

/*START LIST LEGEND*/
.spaced-legend {
  background-color: #fff;
  box-shadow: 0px 0px 10px 2px rgba(62, 44, 90, 0.10);
  border-radius: 3px;
  padding: 10px;

  .title-legend {
    font-family: $font-system;
    font-size: 13px;
    font-weight: 600;
    color: #333;
    margin: 0px 4px 6px;
    display: block;
    width: 100%;
    clear: both;
  }

  .list-legend {
    li {
      font-size: 10px;
      padding: 3px;
      font-weight: 600;
      border: 0;
      z-index: 0;

      .icon-legend {
        width: 21px;
        height: 14px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
      }

      &:first-child {
        border-top: 0;
      }
    }

    .ui-dropdown-trigger {
      background-color: $ui-dropdown-trigger-bg;
      color: $ui-dropdown-trigger-color;

      .fa {
        margin-top: 0.5em;
      }
    }
  }
}

::ng-deep.ui-dropdown.ui-widget {
  .ui-dropdown-trigger {
    border-left: 1px solid #d6d6d6 !important;
  }
}

/*END LIST LEGEND*/

/*START FILTER MENU*/
.filter-menu {
  margin-top: 5px;

  li {
    a {
      border-bottom: none;
      color: $filter-menu-color !important;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 13px;
      transition: 0.1s;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        border-bottom: 2px solid;
        color: $filter-menu-hover-active !important;
      }
    }
  }

  li.active {
    a {
      border-bottom: 2px solid;
      color: $filter-menu-hover-active !important;
      cursor: default;
    }
  }
}

.filter-menu.filter-gray {
  li {
    a {
      color: #666666;
      cursor: default;

      &:hover {
        border-bottom: none;
        color: #666666;
      }
    }
  }
}

.filter-menu.filter-card-header {
  margin-bottom: 3px;

  li {
    a {
      text-transform: none;
      font-size: 12px;
      padding: 5px 0px 10px;
      position: relative;
      top: 0px;
      z-index: 1;
    }
  }
}

.filter-icons {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-left: 10px;
  padding: 0px 10px;
  margin-bottom: 0;

  li {
    margin-right: .2rem;

    a {
      color: #888888 !important;

      &:hover,
      &:focus,
      &:active {
        color: #0074dd !important;
        cursor: pointer;
      }
    }
  }

  li>a>span,
  li>a>i {
    line-height: 1.33;
  }

  li.active a {
    color: #0074dd !important;
    cursor: default;
  }
}

/*END FILTER MENU*/

/*Close Card*/
.close-card {
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s;

  i {
    color: #999999;
  }

  &:hover {
    i {
      color: #dd2c00;
    }
  }
}

::ng-deep.ui-state-highlight {
  border-color: $ui-state-highlight-border;
  background: $ui-state-highlight-bg;
}

::ng-deep.ui-dropdown.ui-state-focus,
.ui-multiselect.ui-state-focus {
  box-shadow: none;
}

::ng-deep.ui-dropdown-panel {

  // top: 32px !important;
  .ui-dropdown-filter-container {
    input {
      outline: none !important;
      border: 1px solid #d6d6d6 !important;
    }

    // .fa{
    //   top: 15px;
    //   font-size: 15px;
    // }
  }

  .ui-dropdown-item {
    padding: 0.37em 0.25em !important;
    font-size: 0.8em !important;
    font-weight: 400 !important;
  }
}

::ng-deep.ui-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3) !important;
}

::ng-deep.ui-dropdown-items {
  background-color: $ui-dropdown-items-bg;
}

/*Custom Panel Dropdrow Table*/
::ng-deep.list-table.ui-dropdown-panel {
  .ui-dropdown-filter-container {
    input {
      padding: 0.150rem 0.45rem !important;
    }

    .fa {
      top: 0.9em;
      font-size: 13px;
    }
  }

  .ui-dropdown-items.ui-dropdown-list {
    font-size: 0.80em !important;
  }
}

// PRIME MULTSELECT
::ng-deep.ui-multiselect {
  width: 100% !important;

  .ui-multiselect-label {
    margin-bottom: 0px !important;
    height: 31px !important;
    font-size: 0.8em;
    padding-top: 6px;
    font-weight: 400;
    color: #222;
    line-height: 1.54;
    padding-left: 7px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

::ng-deep.ui-multiselect {
  &:not(.ui-state-disabled) {
    &:hover {
      border-color: $ui-mult-border-hover;
    }
  }
}

/*Box Activity*/
.spaced-activity {
  &:hover {
    .actions {
      display: block !important;
    }

    .box-activity {
      background-color: #eeeeee;
    }
  }

  .by-date {
    font-size: 13px;
    color: #666666;
    font-weight: 600;

    .user {
      i {
        color: #0091ea;
      }

      font-weight: bold;
    }

    .date-time {
      padding-left: 15px;
      text-align: right;
      font-weight: 600;

      i {
        color: #0091ea;
      }

      color: #666666;

      small {
        color: #999999;
      }
    }

    .actions {
      float: right;
      display: none;

      .edit,
      .delete {
        font-size: 14px;
        margin: 0 2px;
        cursor: pointer;
      }

      .edit {
        i {
          color: #2c9856;

          &:hover {
            color: #00bfa5;
          }
        }
      }

      .delete {
        i {
          color: #a52632;

          &:hover {
            color: #dd2c00;
          }
        }
      }
    }
  }

  .box-activity {
    transition: all .2s;
    border-left: 4px solid #0091ea;
    background-color: #f8f8f8;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 8px;
    margin-bottom: 15px;

    p {
      font-size: 13px;
      margin-bottom: 0;
    }
  }
}

/*END CARDS SYSTEM*/

/*START BUTTONS*/

/*START BTN GROUP FILTER CARD*/
.btn-group-custom {
  .btn {
    color: #333333;
    border-radius: 10px;
    font-weight: 520;

    &:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: #0074dd !important;
      color: #fff;
    }
  }

  .btn.active {
    background-color: #0074dd !important;
    color: #fff;
    cursor: default;
  }

  .dropdown-item {
    font-size: 12px;
    outline: none;
    cursor: pointer;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: #0074dd;
  }
}

/*END BTN GROUP FILTER CARD*/

.btn-link {
  color: $btn-link-color;

  &:hover {
    color: $btn-link-color-hover;
    text-decoration: underline;
  }

  .excel {
    color: $btn-link-color-excel;
  }
}

.btn {
  text-transform: uppercase;
  font-family: $font-system;
  font-size: 0.7rem;
  font-weight: 500;
  padding: 0.375rem 1.3rem;
  margin: 0 2px;

  &:active:focus {
    outline: none;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none !important;

    .ui-multiselect-trigger {
      background-color: $ui-mult-trigger-bg;
      color: $ui-mult-trigger-color;
    }
  }
}

::ng-deep.ui-multiselect-panel {
  .ui-multiselect-item {
    label {
      vertical-align: -webkit-baseline-middle;
      margin-bottom: 5px;
      font-weight: 400;
      color: #222;
    }
  }
}

::ng-deep.ui-multiselect-panel {
  .ui-multiselect-filter-container {
    .fa {
      top: 6px;
    }
  }

  .ui-multiselect-close {
    font-size: 0.9em;
    margin-right: 5px;
    margin-top: 3px;

    &:hover {
      color: #aa0000;
    }
  }
}

::ng-deep.ui-multiselect-header {
  .ui-inputtext {
    padding-left: 18px !important;
    padding-right: 20px !important;
  }

  .fa-search {
    &:before {
      font-size: 10px;
    }
  }
}

::ng-deep.ui-multiselect-panel {
  .ui-multiselect-item {
    font-size: 0.8em;
    padding-top: 4px;
    padding-bottom: 0px;
    margin: 2px;
  }
}

::ng-deep.ui-multiselect-item.ui-state-highlight {
  color: $ui-mult-highlight-color;
  background: $ui-mult-highlight-bg !important;
}

::ng-deep.ui-dialog {
  padding-bottom: 0px;

  .ui-widget {
    .ui-chkbox-box {
      border: none !important;
    }
  }
}

::ng-deep.ui-multiselect-header {
  display: flex;
  align-items: center;

  .ui-multiselect-filter-container {
    width: 100%;
    display: inline-flex;
  }
}

::ng-deep.ui-dropdown.ui-state-focus,
::ng-deep.ui-multiselect.ui-state-focus {
  box-shadow: none;
}

/*Select Toolbar*/
::ng-deep.mat-menu-panel {
  min-width: 112px;
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 400px !important;
  border-radius: 3px;
  outline: 0;
  box-shadow: 0 0 0 0;
  border: 1px solid #F2F5F8;
  font-family: $font-system;
  font-weight: 400;
  font-size: 10px;

  .mat-menu-item {
    color: #8b95a5;
    font-family: $font-system;
    font-weight: 400;
    font-size: 12px;
    line-height: 0;
    height: 36px;
  }

  .mat-menu-item:hover:not([disabled]) {
    background: #02ac95;
    color: white;
  }

  button:focus {
    outline: none;
  }
}

.mat-menu-item:hover:not([disabled]) {
  background: #02ac95;
  color: white;
}

button:focus {
  outline: none;
}

// FIM

/*FORMS*/
.form-group {
  p {
    margin-bottom: 0px;
    font-size: 12px;
    margin-left: 1px;
  }
}

.form-control {
  font-size: 0.75rem;
  line-height: 1.2rem;
  //height: 1.5rem;
  padding: 0.375rem 0.45rem;
  outline: none;
  border: 1px solid #ccc;
  font-family: $font-system;

  &:hover {
    border-color: $accent-color !important;
  }

  &:focus {
    box-shadow: none;
    border-color: $accent-color !important;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.btn.btn-sm {
  padding: 0.25rem 0.8rem;
  font-size: 0.7rem;
  text-transform: inherit;
  border: 1px solid #d6d6d6 !important;
  opacity: 0.7;

  &:hover {
    border-color: #d6d6d6;
  }
}


::ng-deep input:disabled::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #444;
}

::ng-deep input:disabled:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #444;
}

::ng-deep input:disabled::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #444;
}

::ng-deep input:disabled:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #444;
}

.btn.btn-xs {
  padding: 0.15rem 0.5rem !important;
  font-size: 0.675rem !important;
}

.btn.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #333;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-primary {
  background-color: $btn-primary-bg;
  border-color: $btn-primary-border;

  &:hover {
    background-color: $btn-primary-bg-hover;
    border-color: $btn-primary-border-hover;
  }

  &:focus,
  &:active {
    background-color: $btn-primary-bg-focus;
    border-color: $btn-primary-border-focus;
  }
}

.btn-danger {
  background-color: $btn-danger-bg;
  border-color: $btn-danger-border;

  &:hover {
    background-color: $btn-danger-bg-hover;
    border-color: $btn-danger-border-hover;
  }
}

.form-control[readonly] {
  background-color: #fcfcfc;
  border-color: #ededed;
  color: #555555;
  cursor: not-allowed;
}

label {
  font-family: $font-system;
  font-weight: bold;
  color: $label;
  font-size: 11px;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
}

::ng-deep .ui-inputtext {
  display: block !important;
  width: 100% !important;
  border-radius: 0.25rem !important;
  padding: 0.375rem 0.45rem !important;
  line-height: 1.2rem !important;
  font-size: 0.75rem !important;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:enabled:hover {
    border-color: $ui-inputtext-border-hover !important;
  }
}

::ng-deep.ui-widget input,
::ng-deep.ui-widget select,
::ng-deep.ui-widget textarea,
::ng-deep.ui-widget button {
  font-size: 0.8rem;
}

::ng-deep.ui-widget button.btn {
  font-size: 0.7rem;
}

::ng-deep.ui-widget input {
  border: 1px solid #ced4da !important;
  width: 100% !important;
}

::ng-deep.ui-dropdown.ui-state-disabled {
  .ui-dropdown-label {
    cursor: not-allowed !important;
  }

  &:focus,
  &:active {
    background-color: $btn-danger-bg-focus;
    border-color: $btn-danger-border-focus;
  }
}

.btn-secondary {
  background-color: $btn-secondary-bg;
  border-color: $btn-secondary-bg;
  color: #ffffff;

  &:hover {
    background-color: $btn-secondary-bg-hover;
    border-color: $btn-secondary-bg-hover;
    color: #ffffff;
  }

  &:focus,
  &:active {
    background-color: $btn-secondary-bg-focus;
    border-color: $btn-secondary-bg-focus;
    color: #ffffff;
    outline: none;
  }
}

.btn-info {
  background-color: $btn-info-bg;
  border-color: $btn-info-border;
  color: $btn-info-color;

  &:hover {
    background-color: $btn-info-bg-hover;
    border-color: $btn-info-border;
    color: $btn-info-color-hover;
  }

  &:focus,
  &:active {
    background-color: $btn-info-bg-focus;
    border-color: $btn-info-border !important;
    color: $btn-info-color-hover !important;
  }
}

/*linha efeito input mat*/
::ng-deep.mat-form-field-underline,
::ng-deep.mat-form-field-subscript-wrapper {
  display: none;
}

/*input mat*/
::ng-deep.mat-input-element {
  border: 1px solid #ccc !important;
  padding: 0.15rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem;
  width: 100% !important;
  height: 30px;
  outline: none !important;

  &:hover {
    border-color: $form-control-border-hover !important;
  }
}

/*description input*/
::ng-deep.mat-hint {
  font-size: 9px !important;
  text-align: right;
  display: block;
  margin-top: 2px;
}

/*MAT-SLIDER*/
::ng-deep.mat-accent .mat-slider-track-fill,
::ng-deep.mat-accent .mat-slider-thumb,
::ng-deep.mat-accent .mat-slider-thumb-label,
::ng-deep.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb {
  background-color: $mat-slide-toggle-thumb;
}

/*CHECKBOX*/
::ng-deep.mat-checkbox-layout {
  margin-bottom: 0;

  .mat-checkbox-inner-container {
    height: 20px !important;
    width: 20px !important;
  }

  .mat-checkbox-label {
    font-family: $font-system;
    text-transform: uppercase;
    font-weight: bold;
    color: #555;
    font-size: 12px;
  }
}

::ng-deep.mat-checkbox {
  font-family: $font-system;
}

::ng-deep.mat-checkbox-frame {
  transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1) !important;
  border-width: 2px !important;
  border-radius: 2px !important;
}

::ng-deep mat-checkbox.mat-checkbox-checked.mat-accent {
  .mat-checkbox-background {
    background-color: $mat-checkbox-background !important;
  }

  &:disabled {
    background-color: #eee;
    border-color: #eee;
    color: #333;
  }
}

.btn-close-times {
  i {
    font-size: 18px;
    color: #999999;
    padding: 4px 0;

    &:hover {
      color: #666666;
    }
  }
}

::ng-deep.mat-checkbox-indeterminate.mat-accent,
.mat-checkbox-checked.mat-accent {

  .mat-checkbox-background,
  .mat-checkbox-background {
    background-color: $mat-checkbox-background !important;
  }
}

.btn-outline-primary {
  color: $btn-outline-primary-color;
  border-color: $btn-outline-primary-color;

  &:focus,
  &:active,
  &:hover {
    color: #ffffff;
    border-color: $btn-outline-primary-color !important;
    background-color: $btn-outline-primary-color !important;
  }
}

.back-card {
  margin-right: 2px;
  vertical-align: middle;

  .btn {
    padding: 0 !important;

    span.material-icons {
      line-height: 1;
      vertical-align: middle;
      font-size: 20px;
    }
  }
}

/*END BUTTONS*/

/*START TABLES*/
/*Start Ellipsis Dinamic Table (data-table-component)*/
.text-overflow-dynamic-container {
  position: relative;
  max-width: 100%;
  padding: 0 !important;
  display: flow-root;
  vertical-align: text-bottom !important;
}

.text-overflow-dynamic-ellipsis {
  position: absolute;
  white-space: nowrap;
  overflow-y: visible;
  overflow-x: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  top: 0;
  left: 0;
  user-select: all !important;
  height: 100%;
  overflow-y: hidden;
}

.text-overflow-dynamic-container:after,
.text-overflow-dynamic-ellipsis:after {
  content: '-';
  // display: inline; /*estava gerando um quadrado branco no final da célula*/
  display: flex;
  visibility: hidden;
  width: 0;
}

::ng-deep.check-all {
  text-align: left;
  padding: 6px 5.3px;
  position: relative;
  top: 10px;
}

::ng-deep.mat-checkbox-disabled {
  opacity: 0.7;
}

/*RADIO*/
::ng-deep.mat-radio-button {
  font-size: $font-system;
}

::ng-deep.mat-radio-label {
  font-size: $font-system;
  text-transform: uppercase;
  color: #555;
  font-weight: bold;
  font-size: 12px;
}

::ng-deep.mat-radio-label-content {
  font-size: 12px;
}

::ng-deep.mat-radio-button.mat-accent {
  .mat-radio-inner-circle {
    background-color: $mat-radio-background !important;
  }
}

::ng-deep.mat-radio-button.mat-accent.mat-radio-checked {
  .mat-radio-outer-circle {
    border-color: #333 !important;
  }
}

::ng-deep.radio-normal.mat-radio-button {
  .mat-radio-label-content {
    font-weight: normal;
    text-transform: initial;
  }
}

/*End Ellipsis Dinamic Table (data-table-component)*/

/*Reticencias texto overflow*/
.overflow-ellipse {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

::ng-deep.ui-datepicker.ui-widget {
  .ui-datepicker-calendar td a.ui-state-active {
    background-color: $ui-state-active;
  }
}

::ng-deep.type-p-calendar {
  position: relative;
  width: 100%;
  display: block;

  &::before {
    color: $accent-color;
    position: absolute;
    top: 4px;
    left: 10px;
    font-family: "Font Awesome 5 Free";
    font-size: 1rem;
    font-weight: 900;
    content: "\f073";
    z-index: 10;
  }

  .ui-calendar input {
    text-align: right;
  }
}

::ng-deep.type-p-calendar.calendar-small {
  .ui-datepicker {
    .ui-datepicker-title select {
      font-size: 0.7em;
    }
  }

  .ui-datepicker.ui-widget {
    width: 210px;

    .ui-datepicker-calendar {
      thead th {
        padding: 4px;
        font-size: 12px;
      }

      td a {
        padding: 4px;
        font-size: 11px;
      }
    }

    .p-sortable-column .p-sortable-column-icon,
    .p-sortable-column-icon.pi-sort-amount-up-alt,
    .p-sortable-column-icon.pi-sort-amount-down {
      font-size: 0.7rem;
      color: #00bfa5 !important;
      margin-left: 0px;
      background-color: #f4f4f4;
      position: absolute;
      top: 2px;
      width: 0;

      &:before {
        font-family: "Font Awesome 5 Free";
        content: "\f0dc";
        display: inline-block;
        padding-right: 3px;
        vertical-align: middle;
        font-weight: 900;
        font-size: 12px;
      }
    }

    .p-sortable-column-icon.pi-sort-amount-up-alt {
      &:before {
        content: "\f0de" !important;
      }
    }

    .p-sortable-column-icon.pi-sort-amount-down {
      &:before {
        content: "\f0dd" !important;
      }
    }

    .p-multiselect {
      display: flex;
    }
  }

  .p-sortable-column {
    &:hover {
      background-color: transparent !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .p-sortable-column.p-highlight {
    background: transparent;
    color: #333333;

    &:hover {
      background-color: transparent !important;
      color: #333333;
    }
  }
}

::ng-deep.mat-slide-toggle.mat-checked {
  &:not(.mat-disabled) {
    .mat-slide-toggle-bar {
      background-color: $mat-slide-toggle-bar;
    }
  }
}

::ng-deep.mat-slide-toggle.mat-disabled,
.mat-slide-toggle.mat-disabled {

  .mat-slide-toggle-label,
  .mat-slide-toggle-thumb-container {
    cursor: not-allowed !important;
  }
}

::ng-deep.mat-slide-toggle.slide-toggle-normal {
  .mat-slide-toggle-content {
    font-weight: normal;
    text-transform: initial;
  }

  .p-datatable-tbody>tr>td {

    .p-multiselect {
      display: flex;
    }

    /*fix position tooltip*/
    .text-overflow-dynamic-ellipsis {
      overflow-y: hidden;
      max-height: 15px;
    }

    /*Edit Line*/
    .edit-line {
      display: block;
      min-height: 20px;
      cursor: pointer;

      i {
        display: none;
        color: #00bfa5;
      }
    }

    .add-text {
      display: none;
    }

    /*start icons actions last colum*/
    .delete {
      i {
        color: #a52632;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #dd2c00;
        }
      }
    }

    .edit {
      i {
        color: #2c9856;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #00bfa5;
        }
      }
    }

    .attachment,
    .shared {
      i {
        color: #116fbf;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #0091ea;
        }
      }
    }

    .formula {
      i {
        color: #953dac;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #be4bdb;
        }
      }
    }
  }
}

/*MODAL PRIME*/
::ng-deep.ui-widget-overlay {
  background: #000;
}

::ng-deep.ui-dialog.ui-widget {
  .actions-modal {
    position: absolute;
    right: 35px;
    text-align: right;
    top: 18px;

    .excel {
      font-size: 19px !important;
      color: #999999 !important;
    }
  }

  .ui-dialog-titlebar-close span {
    font-size: 24px;

    &::before {
      font-family: "Material Icons";
      content: "\e5cd";
      display: inline-block;
      vertical-align: middle;
    }

    /*end icons actions last colum*/
  }

  .p-datatable-thead>tr>th,
  .p-datatable-tbody>tr>td {
    .p-multiselect {
      .p-multiselect-trigger {
        .p-multiselect-trigger-icon {
          font-size: 0.8rem;
        }

        width: 1.4rem;
      }

      .p-multiselect-label {
        padding: 0.33rem 0.250rem;
        font-size: 0.65rem !important;
      }
    }

    /*Aplicar com class custom para panel*/
    //   .p-multiselect-panel  {
    //     .p-multiselect-items {
    //       .p-multiselect-item{
    //         padding: 0.129rem 0.857rem !important;
    //         font-size: 0.65rem;
    //     }
    //   }
    // }
  }

  .p-datatable-tbody>tr {
    &:hover {
      td {
        .edit-line {
          i {
            display: inline;
            color: #00bfa5;

          }
        }

        .add-text {
          display: block;
        }
      }
    }
  }

  .p-datatable-tbody>tr:not(.p-highlight) {
    &:hover {
      background: $table-td-hover !important;
      font-weight: bold;

      td {
        .edit-line {
          i {
            display: inline;
            color: #00bfa5;

          }
        }

        .add-text {
          display: block;
        }
      }
    }
  }

  .p-datatable-tbody>tr.p-highlight {
    background-color: $table-state-highlight-bg !important;
    font-weight: bold;

    td {
      span {
        color: #ffffff !important;
      }

      .edit,
      .delete {
        i {
          color: #ffffff !important;
        }
      }

      .add-text {
        .btn-link {
          color: #ffffff;
        }
      }

      .action-fade {
        border-color: #fff !important;
      }
    }
  }

  .p-datatable-tfoot>tr>td {
    font-size: 0.7rem !important;
    border-top: 0;

    /*fix position tooltip*/
    .text-overflow-dynamic-ellipsis {
      overflow-y: hidden;
      max-height: 15px;
    }
  }

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    height: 1.3rem;
    min-width: 1.6rem;
    font-size: 0.9rem;
    border-radius: 4px;
  }

  .p-paginator {
    background-color: transparent;
    border: 0;
    margin-top: 10px;

    .p-dropdown {
      height: 1.457rem;

      .p-dropdown-label {
        padding: 2px 8px;
        font-size: 0.7rem;
      }

      .p-dropdown-trigger {
        width: 1.6rem;


      }
    }

    .p-paginator-pages {
      .p-paginator-page {
        height: 1.3rem;
        min-width: 1.6rem;
        font-size: 0.9rem;
        border-radius: 4px;
      }

      .p-paginator-page.p-highlight {
        background: #00bfa5 !important;
        border-color: #00bfa5 !important;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .p-dropdown-trigger-icon {
      font-size: 0.8rem;
    }
  }

  /* START BTN ACTIONS FADE*/
  .action-fade {
    position: absolute;
    right: -30px;
    transition: 0.4s;
    transition-timing-function: ease-in-out;
    opacity: 0;
    border: 1px solid #666;
    border-radius: 3px;
    padding: 4px 6px;
    color: #444;
    margin-top: -18px;

    &:hover {
      color: #999;
    }
  }

  .p-datatable-tbody>tr {
    &:hover {
      .action-fade {
        line-height: 0;
        right: 5px;
        opacity: 1;
        border-color: #999;
        cursor: pointer;
      }

      .action-fade.icon-one {
        right: 66px;
      }

      .action-fade.icon-two {
        right: 35px;
      }

      .action-fade.icon-tree {
        right: 5px;
      }
    }
  }

  /* END BTN ACTIONS FADE*/

  .ui-dialog-content {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ui-dialog-titlebar {
    padding: 15px 7px 10px 15px;

    p-header {
      vertical-align: middle;
    }

    .ui-dialog-title {
      font-size: 0.85em;
      color: #555;
      text-transform: uppercase;
      font-weight: 600;
      vertical-align: middle;
      display: inline-block;
      margin-top: 5px;
    }

    border-bottom: 1px solid #cccccc !important;
  }
}

::ng-deep.ui-dialog {
  .ui-dropdown-panel {
    .ui-dropdown-items-wrapper {
      .ui-dropdown-list {
        border: 0 !important;
      }
    }
  }

  .ui-widget {
    border: 1px solid #d6d6d6 !important;
    border-bottom: 1px solid #d6d6d6 !important;
  }

  .ui-multiselect-items-wrapper {
    .ui-widget-content.ui-widget {
      border: 0 none !important;
    }
  }

  .ui-dialog-titlebar-icon {
    &:hover {
      border-color: transparent;
      background: none;
      color: #dd2c00;
      opacity: 1;
    }

    color: #000;
    opacity: 0.5;
  }

  .table-border {
    .ui-table.ui-widget {
      border: none !important;
      border-bottom: none !important;
    }
  }
}

::ng-deep.ui-dialog.ui-corner-all {
  border-radius: 6px;
}


::ng-deep.ui-dialog.ui-widget {
  .ui-dialog-footer {
    border: none !important;
    border-top: 1px solid #ccc !important;
    margin-top: 5px !important;
    padding: 13px 15px !important;
    border-width: 1px 0 0 0 !important;
  }
}

::ng-deep.ui-dialog {
  .ui-datatable.ui-widget {
    border: none !important;
  }

  .ui-paginator-bottom {
    border: none !important;
  }
}

::ng-deep.modal-header {
  text-transform: uppercase;

  .modal-title {
    font-size: 1rem;
    font-weight: 600;
    color: #555;
    font-family: $font-system;
  }

  .close {

    &:hover,
    &:focus {
      color: #dd2c00;
      outline: none;
    }
  }
}

::ng-deep.modal-scroll {
  .ui-dialog-content.ui-widget-content {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}

::ng-deep.modal-initial {
  .ui-dialog-content.ui-widget-content {
    overflow: initial !important;
  }
}

::ng-deep.modal-scroll-page {
  .ui-dialog-content.ui-widget-content {
    height: auto !important;
  }
}

/*Start Modal Custom*/
::ng-deep.modal-custom.ui-dialog.ui-corner-all {
  border-radius: 10px;
  padding: 5px 0px;
  border: 1px solid #0074dd;
  border-top: 10px solid #0074dd;
  box-shadow: 0px 0px 15px 3px #666 !important;
}

::ng-deep.modal-custom.ui-dialog.ui-widget {
  .ui-dialog-titlebar {
    padding: 2px 5px 8px 15px;

    .ui-dialog-title {
      display: flex;
      align-items: center;
      width: 93%;
      min-height: 30px;
      margin-top: 0;
    }

    .ui-dialog-titlebar-icon {
      position: absolute;
      right: 5px;
      top: 0px;
    }
  }

  .ui-dialog-footer {
    margin-top: 0px !important;
    padding: 12px 15px 8px !important;
  }
}

/*End Modal Custom*/

/*TABS*/
::ng-deep.mat-tab-group {
  font-family: $font-system;
  width: 100%;
}

::ng-deep.mat-tab-header {
  border-bottom: 1px solid #ccc !important;
}

::ng-deep.mat-tab-list {
  .mat-tab-labels {
    background-color: none !important;

    .mat-tab-label-content {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 13px;
    }
  }
}

/*ALERTS*/
.alert.alert-xs {
  padding: 1px 6px;
  font-size: 11px;
}

::ng-deep.card-linnks.margin-linnks .modal-body .ui-messages {
  right: 17px !important;
  bottom: 85px !important;
  width: auto !important;
  max-width: 90% !important;
}

/*Blocked Campos User*/
::ng-deep .lks-form-grid-readOnly {
  .locked-element {
    cursor: not-allowed;
  }

  .div-card-total-nota {
    opacity: 0.7 !important;
    pointer-events: none;
  }

  .fr-toolbar {
    .fr-command.fr-btn {
      opacity: 0.7 !important;
      pointer-events: none;
    }
  }

  .fr-box.fr-basic {
    .fr-element {
      opacity: 0.7 !important;
      pointer-events: none;
    }
  }

  .sub-title {
    opacity: 0.7 !important;
    pointer-events: none;
  }

  .vl-total {
    opacity: 0.7 !important;
    pointer-events: none;
  }

  .lks-p-table {
    opacity: 0.7;
    pointer-events: none;
  }

  .lks-form-grid {
    label {
      opacity: 0.7;
    }
  }

  .botao-adicionar {
    pointer-events: none;
    opacity: 0.7;
  }

  input,
  textarea {
    pointer-events: none;
    opacity: 0.7;
    border-color: #d6d6d6 !important;
    color: #222;
  }

  p-dropdown {
    pointer-events: none;
    opacity: 0.7;
  }

  ::ng-deep.ui-dropdown {
    pointer-events: none;
  }

  p-calendar {
    &:before {
      opacity: 0.7;
    }

    pointer-events: none;
  }

  ::ng-deep .ui-calendar {
    pointer-events: none;
  }

  mat-select {
    pointer-events: none;
    opacity: 0.7;
  }

  ::ng-deep .mat-select {
    pointer-events: none;
    opacity: 0.7;
  }

  mat-checkbox {
    pointer-events: none;
    opacity: 0.7;
  }

  ::ng-deep .mat-checkbox {
    pointer-events: none;
    opacity: 0.7;
  }

  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #444;
  }

  input::-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #444;
  }

  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #444;
  }

  input::-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #444;
  }

  ::ng-deep.ui-state-disabled,
  ::ng-deep.ui-state-disabled {
    opacity: 1;
    background-image: none;
    background-color: transparent;
  }
}

::ng-deep.ui-state-disabled,
::ng-deep.ui-state-disabled {
  opacity: 0.7;
}

// froola
::ng-deep.fr-box {
  position: relative;
}

::ng-deep.fr-toolbar.fr-desktop.fr-top.fr-basic {
  border: 1px solid #ededed;
}

::ng-deep.fr-toolbar.fr-top {
  top: 0;
  border: 1px solid #ededed;
  border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1px 3px rgb(255, 255, 255), 0 1px 1px 1px rgb(255, 255, 255);
  box-shadow: 0 1px 3px rgb(255, 255, 255), 0 1px 1px 1px rgb(255, 255, 255);
}

::ng-deep.fr-toolbar {
  color: #222;
  background: #fff;
  border: 1px solid #ededed;
  font-family: $font-system;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 0;
  border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1px 3px rgb(255, 255, 255), 0 1px 1px 1px rgb(255, 255, 255);
  box-shadow: 0 1px 3px rgb(255, 255, 255), 0 1px 1px 1px rgb(255, 255, 255);
  border: 0;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::ng-deep.fr-box.fr-basic.fr-top .fr-wrapper {
  border-top: 0;
  border: 1px solid #ededed;
  border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1px 3px rgb(255, 255, 255), 0 1px 1px 1px rgb(255, 255, 255);
  box-shadow: 0 1px 3px rgb(255, 255, 255), 0 1px 1px 1px rgb(255, 255, 255);
}

::ng-deep.fr-element img {
  cursor: pointer;
  margin: inherit !important;
}

.class1 {
  border-radius: 10%;
  border: 2px solid #efefef;
}

/*END TABLE NEW ANGULAR 12*/

/*TABLE FIXED HEADER BOOTSTRAP*/
.table-fixed tbody {
  height: 300px;
  overflow-y: auto;
  width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
  display: block;
}

.table-fixed tbody td,
.table-fixed tbody th,
.table-fixed thead>tr>th {
  float: left;
  position: relative;

  &::after {
    content: "";
    clear: both;
    display: block;
  }
}

/*ALINHAMENTO EXCEL*/
.align-down-table {
  position: relative;
  right: -5px;
  padding-right: 5px;
  font-size: 0.7rem;

  .btn {
    font-size: 0.7rem;
    padding-right: 0;
  }
}

/*START table no border Bootstrap*/
.table.table-borderless {
  th {
    border-bottom: 0;
    padding: 0.25rem 0.15rem;
    font-size: 1rem !important;
    border-top: 0;
    font-weight: normal;

    strong {
      font-weight: 600;
    }
  }

  td {
    strong {
      font-weight: 600;
    }

    border-top: 0;
    padding: 0.25rem 0.15rem;
    font-size: 0.7rem !important;

    .valor-total {
      color: #00bfa5;
    }
  }
}

/*END table no border Bootstrap*/

/*Table Card*/
.card-table {
  thead {
    tr {
      th {
        color: #4D5259;
        text-transform: uppercase;
      }
    }
  }

  >ul {
    text-align: left;
    font-size: 0;
    padding: 0;
    list-style: none;

    >li {
      text-align: center;
      width: 25%;
      display: inline-block;
      vertical-align: top;
      padding: 15px 15px;

      >a,
      >span {
        width: 100%;
        display: block;
        padding: 50px 15px;
        cursor: pointer;
        border: 1px solid #eee;
        background: #fff;
        transition: 0.25s;
      }
    }
  }
}

/*START table border Bootstrap*/
.table.table-border-boot {
  th {
    background: $table-border-head-bg;
    border: 1px solid #cccccc;
    font-size: 11px;
    font-family: $font-system;
    text-transform: uppercase;
    padding: 6px 10px;
    font-weight: bold;

    strong {
      font-weight: 600;
    }
  }

  tr {
    &:hover {
      td {
        background: $table-td-hover;
        font-weight: bold;
      }
    }
  }

  td {
    background: inherit;
    font-size: 11px;
    vertical-align: middle;
    padding: 4px 10px;
    font-family: $font-system;
    border: 1px solid #cccccc;

    strong {
      font-weight: 600;
    }

    .delete {
      i {
        color: #a52632;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #dd2c00;
        }
      }
    }

    .edit {
      i {
        color: #2c9856;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #00bfa5;
        }
      }
    }

    .attachment,
    .shared {
      i {
        color: #116fbf;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #0091ea;
        }
      }
    }

    .formula {
      i {
        color: #953dac;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #be4bdb;
        }

        .sub-titulo {
          color: #333;
          font-size: 9px;
          font-weight: 400;
          text-transform: uppercase;
          display: block;
          margin: 0px 0 0;
          -webkit-transition: 0.5s;
          transition: 0.5s;
          -webkit-transform: translateY(0%);
          transform: translateY(0%);
        }

        .titulo {
          color: #333;
          font-size: 16px;
          font-weight: 400;
          text-transform: uppercase;
          display: block;
          margin: 25px 0 0;
          transition: 0.5s;
          transform: translateY(0%);
        }
      }
    }
  }
}

/*END table border Bootstrap*/

/*START table stripe Bootstrap*/
.table-striped {
  tbody {
    tr:nth-of-type(odd) {
      background-color: transparent;
    }
  }
}

.table-striped {
  tbody {
    tr:nth-of-type(even) {
      background-color: #fafafb;
    }
  }
}

/*END table stripe Bootstrap*/

/*START TABLE BORDERLESS ICONES ACTION*/
.table.table-borderless {
  tr>td {
    .delete {
      i {
        color: #a52632;
        cursor: pointer;
        padding: 0 2px;

        &:hover {
          color: #dd2c00;
        }
      }
    }

    .edit {
      i {
        color: #2c9856;
        cursor: pointer;
        padding: 0 2px;

        .card-body {
          padding: 1.5rem 0.75rem;
          border-radius: 0.25rem;
          display: flex;
          align-items: center;

          .icon-card {
            display: inline-table;
            background: #eeeeee;
            cursor: pointer;
            border-radius: 100%;
            width: 45px;
            height: 45px;
            margin-right: 8px;

            i {
              text-align: center;
              display: block;
              color: #4d5259;
              font-size: 22px;
              padding: 12px;
              transition: 0.2s;
            }

            &:hover {
              color: #00bfa5;
            }
          }
        }

        .attachment,
        .shared {
          i {
            color: #116fbf;
            cursor: pointer;
            padding: 0 2px;

            &:hover {
              color: #0091ea;
            }
          }
        }

        .formula {
          i {
            color: #953dac;
            cursor: pointer;
            padding: 0 2px;

            .info-card {
              display: inline-table;
            }

            .card-title {
              display: inline-flex;
              margin-bottom: 1px;
              font-size: 0.85rem;
              font-weight: bold;
              color: #666;
              cursor: pointer;
              transition: 0.2s;

              &:hover {
                color: #be4bdb;
              }
            }
          }

          .default {
            i {
              color: #555;
              cursor: pointer;
              padding: 0 2px;

              &:hover {
                color: #888;
              }
            }
          }

          .card-text {
            display: flex;
            font-size: 0.80rem;
            width: auto !important;

            .text-muted {
              .date {
                color: #777777;
                text-decoration: underline;

                i {
                  color: #0091ea;
                }
              }
            }
          }
        }

        margin-bottom: 1.2rem;
        min-width: 245px;
        max-width: 245px;
        border: 0;
      }
    }
  }
}

/*END TABLE BORDERLESS ICONES ACTION*/

/*START Table Soft*/
.table-soft {
  thead {
    tr {
      th {
        font-size: 12px;
        font-family: $font-system;
        font-weight: bold;
        padding: 5px 8px;
        background-color: #d8e1ea;
        border-color: #bbb;
        border-bottom: 0;
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 11px;
        font-family: $font-system;
        padding: 5px 8px;
        border-color: #ddd;
        border-top-color: #bbb;
      }
    }
  }
}

/*END Table Soft*/

/*START TREETABLE PRIMENG*/
::ng-deep.table-tree {
  th.ui-state-default {
    font-size: 12px;
    background-color: $table-tree-head-bg;
    text-overflow: ellipsis;
  }

  .ui-treetable {
    tbody {
      td {
        &:first-child {
          span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .ui-treetable-toggler {
      color: $table-tree-toggler;
      font-size: 16px;
      float: left;
      margin-top: 3px;
    }
  }

  .ui-treetable tbody .ui-treetable-row {
    td {
      position: relative;
      font-size: 11px;
      text-overflow: ellipsis;
      padding: 0;

      >div {
        &:hover {
          font-weight: bold;
        }

        padding: .25em .5em;
        min-height: 23px;
      }

      >div.nivel1 {
        background-color: #f6f6f6;
        font-weight: bold;
        color: #555555;
      }

      .comments {
        cursor: pointer;

        &::before {
          font-family: "Font Awesome 5 Free";
          content: "\f0d9";
          font-weight: 900;
          position: absolute;
          right: -2px;
          top: -9px;
          transform: rotate(-222deg);
          font-size: 14px;
          color: darkred;
        }
      }
    }
  }
}

/*END TREETABLE PRIMENG*/

::ng-deep table {
  thead {
    tr>th {
      .mat-select {
        border: none !important;
      }
    }
  }
}

/*CARD DASHBOARD*/
.card-outline-primary,
.card-outline-primary-colorgrey {
  background-color: #ffffff;
  border: 2px solid #0074dd;
  border-radius: 10px;
  color: #777;
  padding: 4px;
  margin: 0px;
  font-family: $font-system;
  transition: all 0.2s;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    background-color: #0074dd;
    color: #ffffff;

    .percentenge {
      .status-percentenge {
        color: #ffffff !important;
      }
    }
  }

  .title-card {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: normal;
    min-height: 30px;
  }

  .value {
    font-weight: bold;
    font-size: 1.1rem;
  }

  .percentenge {
    text-align: right;
    display: block;
    font-size: 0.8rem;
    margin-bottom: 6px;

    .status-percentenge {
      font-size: 16px;
      vertical-align: middle;
      position: relative;
      top: -2px;
    }

    .status-percentenge.positive {
      color: green;
    }

    .status-percentenge.negative {
      color: darkred;
    }
  }

  .info-secondary {
    .info-1 {
      display: block;
      font-size: 13px;
      line-height: 5px;
    }

    .info-2 {
      font-size: 12px;
    }
  }
}

.card-outline-primary.active {
  background-color: #0074dd;
  color: #ffffff;

  .status-percentenge {
    color: #ffffff !important;
  }
}

.card-outline-primary-colorgrey {
  border: 1px solid;
  height: 70px;

  &:hover {
    background-color: transparent;
    color: inherit;
    box-shadow: 0 0 8px 1px #dddddd;
  }

}
/*END TABLES*/

/*START ACCORDION ANGULAR 12*/
::ng-deep.p-accordion {

  .p-accordion-header:not(.p-disabled).p-highlight,
  .p-accordion-header {
    .p-accordion-header-link {


      .p-accordion-toggle-icon {
        font-size: 0.75rem;
        font-weight: bold;
      }

      background: #f6f7f9;
      border-color: #d9d9d9;
      color: #666;
      font-size: 0.8rem;
      padding: 0.6rem 1rem;
      font-weight: 550;
      text-decoration: none !important;

      &:hover {
        background: #ededf0 !important;
        border-color: #d9d9d9 !important;
        color: #666 !important;
      }
    }
  }
}

.card-primary {

  .title-card {
    font-weight: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .percentenge {
    text-align: center;
    font-size: 14.5px;
    font-weight: bold;
    vertical-align: text-top;
  }
}

.card-primary {
  background-color: #0074dd;
  border-radius: 10px;
  padding: 4px;
  margin: 0px;
  min-height: 75px;
  font-family: $font-system;
  transition: all 0.2s;
  color: #ffffff;

  >div {
    border-right: 1px solid;

    &:last-child {
      border-right: none;
    }
  }

  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover {
    .p-accordion-header-link {
      background-color: #eeeeee;
      border: 1px solid silver;
      color: #333333;
    }
  }

  p-accordiontab {
    .p-accordion-tab {
      margin-bottom: 15px;
    }
  }
}

/*END ACCORDION ANGULAR 12*/

/*START TABS*/

/*START Yes Overflow Tabs*/
::ng-deep.overflow-tabs {
  .mat-tab-body {
    .mat-tab-body-content {
      overflow-x: auto !important;
    }
  }

  .mat-tab-body.mat-tab-body-active {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  .mat-tab-body-wrapper {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  .info {
    .align-badge {
      position: absolute;
      top: -7px;
      margin-left: -15px;
      font-size: 10px;
      border-radius: 50%;
      min-width: 18px;
      min-height: 17px;
      padding: 3px;
      text-align: center;
    }

    small {
      font-size: 70%;
    }

    h6 {
      font-weight: bold;
      font-size: 0.8rem;
    }
  }
}

/*ACCORDION*/
::ng-deep.ui-accordion {
  .ui-accordion-header {
    margin-bottom: 10px;

    .ui-accordion-toggle-icon {
      &:before {
        color: $accordion-icon;
      }
    }
  }

  .ui-accordion-header.ui-state-disabled a {
    text-decoration: none;
  }
}

::ng-deep.ui-accordion {
  .ui-accordion-content {
    padding-top: 0px;
  }
}

/*END Yes Overflow Tabs*/

/*START Not Overflow Tabs*/
::ng-deep.mat-tab-body-content,
::ng-deep.mat-tab-body.mat-tab-body-active,
::ng-deep.mat-tab-body-wrapper {
  overflow: initial !important;
}

::ng-deep.mat-tab-body {
  .mat-tab-body-content {
    overflow: initial !important;
  }
}

/*END Not Overflow Tabs*/

/*START MATERIAL TABS*/
.mat-tab-labels {
  display: flex;
  background-color: transparent;
}

.mat-tab-label {
  font-family: $font-system;
  color: #181818;
  font-weight: 400;
  font-size: 12px;
  height: 35px !important;
  background-color: transparent !important;
  padding: 0 24px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: .6;
  min-width: 120px !important;
  text-align: center;
  display: inline-flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
  border: #d0d0d0 0.8px;
  border-style: solid;
}

.mat-tab-label-active {
  font-weight: 500;
  color: #4D5259;
  opacity: 1 !important;
  background-color: #FFF !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #00BFA5;
  height: 1px !important;
}

.mat-tab {
  box-shadow: 0 0 0 0;
}

::ng-deep.mat-tab-group {
  font-family: $font-system;
  width: 100%;
}

::ng-deep.mat-tab-header {
  border-bottom: 1px solid #ccc !important;
}

::ng-deep.mat-tab-list {
  .mat-tab-labels {
    background-color: none !important;

    .mat-tab-label-content {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 13px;
    }
  }
}

::ng-deep.ui-picklist.ui-picklist-caption {
  background-color: #f4f4f4;
  color: #444444;
  border: 1px solid #c8c8c8;
}

::ng-deep.ui-widget-content.ui-picklist-list {
  border: 1px solid #EDEDED !important;
  border-radius: 2px;
}

::ng-deep.ui-picklist {
  .ui-picklist-list li {
    padding: 2px 10px;
    margin: 0;
    width: 100%;
  }

  .ui-picklist-filter-container {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-color: #EDEDED !important;
  }
}

::ng-deep.ui-picklist-caption.ui-widget-header {
  background-color: #f0f0f0 !important;
  font-weight: 700 !important;
  font-size: 11px;
  line-height: 2;
  text-transform: uppercase;
  color: #666;
  border-bottom: 1px solid #1e70cd !important;
}

/*END MATERIAL TABS*/

/*END TABS*/

/*START MODAL*/

/*TRATAMENTO Z-INDEX TOOLTIP MODAL*/
::ng-deep.cdk-overlay-container {
  z-index: 9999;
}

/*Start Modal Custom*/
::ng-deep.modal-custom.ui-dialog.ui-corner-all {
  border-radius: 10px;
  padding: 5px 0px;
  border: 1px solid #0074dd;
  border-top: 10px solid #0074dd;
  box-shadow: 0px 0px 15px 3px #666 !important;
}

::ng-deep.modal-custom.ui-dialog.ui-widget {
  .ui-dialog-titlebar {
    padding: 2px 5px 8px 15px;

    .ui-dialog-title {
      display: flex;
      align-items: center;
      width: 93%;
      min-height: 30px;
      margin-top: 0;
    }

    .ui-dialog-titlebar-icon {
      position: absolute;
      right: 5px;
      top: 0px;
    }
  }

  .ui-dialog-footer {
    margin-top: 0px !important;
    padding: 12px 15px 8px !important;
  }
}

/*End Modal Custom*/

/* Start modal angular 12 */
::ng-deep.modal-initial {
  .p-dialog-content {
    overflow: initial !important;
  }
}

::ng-deep.modal-scroll {
  .p-dialog-content {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
}

::ng-deep.p-dialog {
  border-radius: 6px;

  .actions-modal {
    position: absolute;
    right: 35px;
    text-align: right;
    top: 18px;

    .excel {
      font-size: 19px !important;
      color: #999999 !important;
    }
  }

  .p-dialog-content {
    padding: 1rem 1.5rem;
  }

  .p-dialog-header {
    background-color: #FFF;
    border-bottom: 1px solid #cccccc;
    text-transform: uppercase;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    color: #555555;
    padding: 0.938rem 0.438rem 0.625rem 0.938rem;

    .p-dialog-header-icon {
      color: #000 !important;
      opacity: 0.5;

      &:hover {
        border-color: transparent !important;
        background: none !important;
        color: #dd2c00 !important;
        opacity: 1;
      }
    }
  }
}

/*LIST LOG*/
.list-log {
  box-shadow: 0px 0px 10px 2px rgba(62, 44, 90, 0.10);
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;

  li {
    font-size: 13px;
    padding: 7px 5px;
    font-weight: 600;
    border-radius: 4px;
    border: none;
    z-index: 0;

    i {
      color: #ffc107;
      padding-right: 2px;
      position: relative;
      top: 1px;
    }
  }

  li:nth-child(odd) {
    background-color: #ffffff;
  }

  li:nth-child(even) {
    background-color: #e6e6e6;
  }
}

/*LIST LEGEND*/
.spaced-legend {
  background-color: #fff;
  box-shadow: 0px 0px 10px 2px rgba(62, 44, 90, 0.10);
  border-radius: 3px;
  padding: 10px;

  .title-legend {
    font-family: $font-system;
    font-size: 13px;
    font-weight: 600;
    color: #333;
    margin: 0px 4px 6px;
    display: block;
    width: 100%;
    clear: both;
  }

  .list-legend {
    li {
      font-size: 10px;
      padding: 3px;
      font-weight: 600;
      border: 0;
      z-index: 0;

      .icon-legend {
        width: 21px;
        height: 14px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
      }

      &:first-child {
        border-top: 0;
      }

      // &:hover{
      //   box-shadow: 0px 0px 10px 2px rgba(62, 44, 90, 0.10);
      // }
    }
  }
}

/*FILTER MENU*/
.filter-menu {
  margin-top: 5px;

  li {
    a {
      border-bottom: none;
      color: $filter-menu-color !important;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 13px;
      transition: 0.1s;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        border-bottom: 2px solid;
        color: $filter-menu-hover-active !important;
      }
    }
  }

  li.active {
    a {
      border-bottom: 2px solid;
      color: $filter-menu-hover-active !important;
      cursor: default;
    }
  }
}

.filter-menu.filter-gray {
  li {
    a {
      color: #666666;
      cursor: default;

      &:hover {
        border-bottom: none;
        color: #666666;
      }
    }

    .p-dialog-title {
      font-size: 0.95rem;
      font-weight: 600;
    }
  }
}

.filter-menu.filter-card-header {
  margin-bottom: 3px;

  li {
    a {
      text-transform: none;
      font-size: 12px;
      padding: 5px 0px 10px;
      position: relative;
      top: 0px;
      z-index: 1;
    }
  }

  .p-dialog-footer {
    padding: 13px 15px !important;
  }
}

/* End modal angular 12 */

/*END MODAL*/

/*START PICK-LIST ANGULAR 12*/
::ng-deep.p-picklist {
  .p-picklist-header {
    border: none;
    border-bottom: 1px solid #1e70cd;
    padding: 0.557rem 1rem;
    background-color: #f0f0f0;

    .p-picklist-title {
      color: #666;
      text-transform: uppercase;
      font-size: 0.7rem;
      text-align: center;
    }
  }

  .p-picklist-filter-container {
    border: 1px solid #ccc;
    border-top: 0;
    border-bottom: 0;
    padding: 0.55rem;
  }

  .p-picklist-list {
    min-height: 220px;
    max-height: 220px;

    .p-picklist-item {
      padding: 0.229rem 0.857rem;
      font-size: 0.70rem;

      &:focus {
        box-shadow: none;
      }
    }

    .p-picklist-item.p-highlight {
      background: #00bfa5;
    }
  }

  .p-picklist-buttons {
    .p-button {
      background-color: #f0f0f0;
      color: #333;
      padding: 0.2rem;
      border: 0;
      box-shadow: none;

      &:hover {
        background-color: #1e70cd;
        color: #ffffff;
      }

      .p-button-icon {
        line-height: 0;

        &::before {
          font-size: 0.75rem;
        }
      }
    }
  }
}

.filter-icons {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-left: 10px;
  padding: 0px 10px;
  margin-bottom: 0;

  li {
    margin-right: .2rem;

    a {
      color: #888888 !important;

      &:hover,
      &:focus,
      &:active {
        color: #0074dd !important;
        cursor: pointer;
      }
    }
  }

  li>a>span,
  li>a>i {
    line-height: 1.33;
  }

  li.active a {
    color: #0074dd !important;
    cursor: default;
  }
}

/*Close Card*/
.close-card {
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s;

  i {
    color: #999999;
  }

  &:hover {
    i {
      color: #dd2c00;
    }
  }

  .p-picklist-buttons.p-picklist-source-controls,
  .p-picklist-buttons.p-picklist-target-controls {
    display: none;
  }
}

/*END PICK-LIST ANGULAR 12*/

//print
.print {
  position: absolute;
  top: 0;
  display: none;
}

/*Custom Panel Dropdrow Table OFX*/
::ng-deep.list-table.ui-dropdown-panel {
  .ui-dropdown-filter-container {
    input {
      padding: 0.150rem 0.45rem !important;
    }

    .fa {
      top: 0.9em;
      font-size: 13px;
    }
  }

  .ui-dropdown-items.ui-dropdown-list {
    font-size: 0.80em !important;
  }
}

/*START LIST*/

/*GRID BUTTONS MÓDULO*/
.lks-relatorio-list {
  width: 100%;
  display: block;

  * {
    text-decoration: none;
  }

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  >ul {
    text-align: left;
    font-size: 0;
    padding: 0;
    list-style: none;

    >li {
      text-align: center;
      width: 25%;
      display: inline-block;
      vertical-align: top;
      padding: 15px 15px;

      >a,
      >span {
        width: 100%;
        display: block;
        padding: 50px 15px;
        cursor: pointer;
        border: 1px solid #eee;
        background: #fff;
        transition: 0.25s;

        &:hover {
          border-color: #fff;
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.025);
          transform: scale(1.02);

          .icone,
          .titulo,
          .sub-titulo {
            color: $grid-icon-color-hover;
          }
        }
      }
    }
  }
}

/*Box Activity*/
.spaced-activity {
  &:hover {
    .actions {
      display: block !important;
    }

    .box-activity {
      background-color: #eeeeee;
    }
  }

  .by-date {
    font-size: 13px;
    color: #666666;
    font-weight: 600;

    .user {
      i {
        color: #0091ea;
      }

      font-weight: bold;
    }

    .date-time {
      padding-left: 15px;
      text-align: right;
      font-weight: 600;

      i {
        color: #0091ea;
      }

      color: #666666;

      small {
        color: #999999;
      }
    }

    .actions {
      float: right;
      display: none;

      .edit,
      .delete {
        font-size: 14px;
        margin: 0 2px;
        cursor: pointer;
      }

      .edit {
        i {
          color: #2c9856;

          &:hover {
            color: #00bfa5;
          }
        }
      }

      .icone {
        color: $grid-icon-color;
        width: 125px;
        height: 125px;
        display: table;
        margin: auto;
        border-radius: 100%;
        background: #f5f5f5;
        transition: 0.5s;
        /*transform: translate3d(0,0,0);*/
        transform: translateY(0%);

        i {
          font-size: 55px;
          display: table-cell;
          vertical-align: middle;

          .delete {
            i {
              color: #a52632;

              &:hover {
                color: #dd2c00;
              }
            }
          }

          .sub-titulo {
            color: #333;
            font-size: 9px;
            font-weight: 400;
            text-transform: uppercase;
            display: block;
            margin: 0px 0 0;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
          }

          .titulo {
            color: #333;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            display: block;
            margin: 25px 0 0;
            transition: 0.5s;
            transform: translateY(0%);
          }
        }
      }
    }
  }
}

/*LIST LOG*/
.list-log {
  box-shadow: 0px 0px 10px 2px rgba(62, 44, 90, 0.10);
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;

  li {
    font-size: 13px;
    padding: 7px 5px;
    font-weight: 600;
    border-radius: 4px;
    border: none;
    z-index: 0;

    i {
      color: #ffc107;
      padding-right: 2px;
      position: relative;
      top: 1px;
    }
  }

  .box-activity {
    transition: all .2s;
    border-left: 4px solid #0091ea;
    background-color: #f8f8f8;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 8px;
    margin-bottom: 15px;

    p {
      font-size: 13px;
      margin-bottom: 0;
    }
  }

  li:nth-child(odd) {
    background-color: #ffffff;
  }

  li:nth-child(even) {
    background-color: #e6e6e6;
  }
}

/*END LIST*/

/*START CALENDAR COMPONENT*/
::ng-deep .cal-month-view {
  .cal-day-cell {
    min-height: 60px;
  }

  .cal-cell-top {
    min-height: 60px;
  }

  .cal-day-cell.cal-today {
    background-color: #e3f2fd;
  }

  .cal-day-number {
    font-size: 1em;
    font-weight: 600;
    opacity: 0.7;
    margin-top: 0px;
    margin-right: 5px;
  }

  .cal-day-cell.cal-today {
    .cal-day-number {
      font-size: 1.3em;
    }
  }

  .cal-day-badge {
    margin-top: 5px;
    margin-left: 5px;
    padding: 3px 6px;
    font-size: 12px;
    background-color: #eee;
    color: #666;
    border: 1px solid #ddd;
  }

  .cal-open-day-events {
    background-color: #f8fafb;
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.1);
  }

  .cal-event-title {
    &:link {
      color: #333;
      font-size: 13px;
      font-weight: 600;
    }
  }

  .cal-day-cell.cal-out-month {
    background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.02) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.02) 50%, rgba(0, 0, 0, 0.02) 75%, transparent 75%, transparent);
    background-color: #FAFCFD;
    background-size: 1rem 1rem;

    .cal-day-number,
    .cal-day-badge {
      opacity: 0.5;
    }
  }

  .cal-cell-row {
    &:hover {
      background-color: transparent;
    }

    .cal-cell.cal-out-month {
      &:hover {
        background-color: #FAFCFD !important;
      }
    }

    .cal-cell {
      &:hover {
        background-color: #fafafa;
      }
    }

    .cal-day-cell.cal-today {
      &:hover {
        background-color: #e3f2fd;
      }
    }
  }

  .cal-day-cell.cal-in-month.cal-has-events {
    background-color: transparent !important;
  }

  .cal-header {
    .cal-cell {
      background-image: linear-gradient(to top, #f2f2f2 0, #fafafa 100%);
      border: 1px solid #e9e9e9;
      color: #555;
      text-transform: capitalize;
      padding: 2px 0;
      font-size: 12px;
    }
  }
}

/*Tooltip Calendar Component*/
::ng-deep.cal-tooltip {
  .cal-tooltip-inner {
    font-size: 13px;
    font-weight: 600;
  }
}

/*CALENDAR COMPONENT*/
::ng-deep {
  @media screen and (min-width: 1280px) {
    .cal-month-view {
      .cal-day-cell {
        min-height: 80px;
      }
    }
  }
}

::ng-deep {
  @media screen and (min-width: 1800px) {
    .cal-month-view {
      .cal-day-cell {
        min-height: 100px;
      }
    }
  }
}

/*END CALENDAR COMPONENT*/

/*START PROGRESSBAR COMPONENT*/
::ng-deep.progressbar-table {
  .ui-progressbar-determinate {
    height: auto;
    min-height: 20px;

    .ui-progressbar-label {
      text-align: right;
    }
  }
}

::ng-deep.progressbar-table.bg-orange {
  .ui-progressbar-determinate {
    .ui-progressbar-value {
      background: orange;
    }
  }
}

::ng-deep.progressbar-table.bg-red {
  .ui-progressbar-determinate {
    .ui-progressbar-value {
      background: tomato;
    }
  }
}

/*END PROGRESSBAR COMPONENT*/

/*STEPS*/
::ng-deep .steps-block {
  .ui-steps {
    position: relative;

    .ui-steps-item {
      cursor: default;
      display: flex;
      justify-content: center;
      float: none;
      border: none;
      border-radius: 3px;
      margin-bottom: 40px;
      position: relative;
      transition: all 0.7s;

      &:hover {
        background-color: #fff !important;
      }

      &:before {
        content: "";
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 95%;
        height: 100%;
        border-left: 2px #ccc solid;

      }

      &:last-child {
        &:before {
          display: none;
        }
      }

      .ui-menuitem-link {
        padding: 4px 1em;

        &:hover {
          .ui-steps-number {
            background-color: #EDEDED;
          }
        }
      }
    }

    .ui-steps-number {
      font-size: 130%;
      display: block;
      border-radius: 115px;
      border: 1px solid #d6d6d6;
      width: 40px;
      height: 40px;
      padding: 0.4rem;
      margin: 0 auto;
    }

    .ui-steps-title {
      margin-top: 4px;
      font-size: 0.8rem;
      text-transform: capitalize;
    }

    .ui-steps-item.ui-state-highlight {
      background: #fff !important;
      color: #333;
      border: none;

      .ui-steps-number {
        border-color: #00bfa5;
        background-color: #00bfa5;
        color: #fff;
        font-weight: bold;
        font-size: 120%;
      }

      &:hover {
        .ui-steps-number {
          background-color: #00bfa5;
        }
      }

      .ui-steps-title {
        color: #666;
        font-weight: bold;
        font-size: 0.77rem;
      }
    }
  }
}

/*@ MEDIA QUERY*/
::ng-deep {
  @media screen and (max-width: 1600px) {

    /*campo data*/
    .type-p-calendar {
      &::before {
        top:8px;
        left: 4px;
        font-size: 85%;
      }
    }

    /*label*/
    label {
      font-size: 10px !important;
    }

    .form-group {
      label {
        line-height: 20px;
      }

      p {
        font-size: 10px;
      }
    }

    /*form*/
    .ui-inputtext,
    .form-control {
      font-size: 0.66rem !important;
    }

    /*prime select*/
    .ui-dropdown-panel {
      .ui-dropdown-item {
        font-size: 0.66rem !important;
      }
    }

    // MAT SELECTS
    .mat-select {
      .mat-select-value {
        font-size: 0.66rem !important;

        .mat-select-value-text {
          font-size: 0.66rem !important;
        }
      }
    }

    .mat-select-panel {
      .mat-option {
        font-size: 0.66rem !important;
      }
    }

    /*sub-title*/
    .sub-title {
      font-size: 13px !important;
    }

    /*btn*/
    .btn {
      font-size: 0.7rem !important;
    }

    .btn.btn-sm {
      padding: 0.15rem 0.5rem !important;
      font-size: 0.675rem !important;
    }

    /*alert*/
    .alert.alert-xs {
      font-size: 10px !important;
    }

    /*Title P-Dialog*/
    .ui-dialog.ui-widget {
      .ui-dialog-titlebar {
        .ui-dialog-title {
          font-size: 0.95em;
        }
      }
    }

    /*CheckBox*/
    .mat-checkbox-layout {
      .mat-checkbox-label {
        font-size: 10px;
      }
    }

    /*MatCard Title*/
    .mat-card-title {
      font-size: 16px !important;
    }

    /*Froala Font*/
    .fr-element p {
      font-size: inherit !important;
    }

    .ui-accordion {
      .ui-accordion-header a {
        font-size: 14px !important;
      }
    }

    /* START BTN ACTIONS FADE*/
    .action-fade {
      padding: 4px !important;
    }

    .ui-datatable tbody>tr.ui-widget-content {
      &:hover {
        .action-fade {
          font-size: 12px !important;
        }
      }
    }

    /* END BTN ACTIONS FADE*/

  }

  .div-card-total.div-card-total.card-total-radius {
    .titulo-card-total {
      font-size: 11px;
    }

    .valor-card-total {
      font-size: 18px;
    }
  }
}

/* START NEW BREAKPOINT SM*/
::ng-deep {
  @media(max-width:1366px) {
    .card-outline-primary {
      .title-card {
        min-height: 25px !important;
        font-size: 0.6rem !important;
      }
    }
  }
}

/*STEPS*/
::ng-deep .steps-block {
  .ui-steps {
    position: relative;

    .ui-steps-item {
      cursor: default;
      display: flex;
      justify-content: center;
      float: none;
      border: none;
      border-radius: 3px;
      margin-bottom: 40px;
      position: relative;
      transition: all 0.7s;

      &:hover {
        background-color: #fff !important;
      }

      &:before {
        content: "";
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 95%;
        height: 100%;
        border-left: 2px #ccc solid;

      }

      &:last-child {
        &:before {
          display: none;
        }
      }

      .ui-menuitem-link {
        padding: 4px 1em;

        &:hover {
          .ui-steps-number {
            background-color: #EDEDED;
          }
        }
      }

    }

    .ui-steps-number {
      font-size: 130%;
      display: block;
      border-radius: 115px;
      border: 1px solid #d6d6d6;
      width: 40px;
      height: 40px;
      padding: 0.4rem;
      margin: 0 auto;
    }

    .ui-steps-title {
      margin-top: 4px;
      font-size: 0.8rem;
      text-transform: capitalize;
    }

    .ui-steps-item.ui-state-highlight {
      background: #fff !important;
      color: #333;
      border: none;

      .ui-steps-number {
        border-color: #00bfa5;
        background-color: #00bfa5;
        color: #fff;
        font-weight: bold;
        font-size: 120%;
      }

      &:hover {
        .ui-steps-number {
          background-color: #00bfa5;
        }
      }

      .ui-steps-title {
        color: #666;
        font-weight: bold;
        font-size: 0.77rem;
      }
    }
  }
}

/* START NEW BREAKPOINT SIZE XXL*/
::ng-deep {
  @media(min-width:1600px) {
    .col-xxl {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%
    }

    .col-xxl-auto {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none
    }

    .col-xxl-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%
    }

    .col-xxl-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%
    }

    .col-xxl-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%
    }

    .col-xxl-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%
    }

    .col-xxl-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%
    }

    .col-xxl-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%
    }

    .col-xxl-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%
    }

    .col-xxl-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%
    }

    .col-xxl-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%
    }

    .col-xxl-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%
    }

    .col-xxl-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%
    }

    .col-xxl-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%
    }

    /*SPACED*/
    html:not([dir=rtl]) .p-xxl-0 {
      padding: 0 !important
    }

    html:not([dir=rtl]) .pt-xxl-0,
    html:not([dir=rtl]) .py-xxl-0 {
      padding-top: 0 !important
    }

    html:not([dir=rtl]) .pr-xxl-0,
    html:not([dir=rtl]) .px-xxl-0 {
      padding-right: 0 !important
    }

    html:not([dir=rtl]) .pb-xxl-0,
    html:not([dir=rtl]) .py-xxl-0 {
      padding-bottom: 0 !important
    }

    html:not([dir=rtl]) .pl-xxl-0,
    html:not([dir=rtl]) .px-xxl-0 {
      padding-left: 0 !important
    }

    html:not([dir=rtl]) .p-xxl-1 {
      padding: .25rem !important
    }

    html:not([dir=rtl]) .pt-xxl-1,
    html:not([dir=rtl]) .py-xxl-1 {
      padding-top: .25rem !important
    }

    html:not([dir=rtl]) .pr-xxl-1,
    html:not([dir=rtl]) .px-xxl-1 {
      padding-right: .25rem !important
    }

    html:not([dir=rtl]) .pb-xxl-1,
    html:not([dir=rtl]) .py-xxl-1 {
      padding-bottom: .25rem !important
    }

    html:not([dir=rtl]) .pl-xxl-1,
    html:not([dir=rtl]) .px-xxl-1 {
      padding-left: .25rem !important
    }

    html:not([dir=rtl]) .p-xxl-2 {
      padding: .5rem !important
    }

    html:not([dir=rtl]) .pt-xxl-2,
    html:not([dir=rtl]) .py-xxl-2 {
      padding-top: .5rem !important
    }

    html:not([dir=rtl]) .pr-xxl-2,
    html:not([dir=rtl]) .px-xxl-2 {
      padding-right: .5rem !important
    }

    html:not([dir=rtl]) .pb-xxl-2,
    html:not([dir=rtl]) .py-xxl-2 {
      padding-bottom: .5rem !important
    }

    html:not([dir=rtl]) .pl-xxl-2,
    html:not([dir=rtl]) .px-xxl-2 {
      padding-left: .5rem !important
    }

    html:not([dir=rtl]) .p-xxl-3 {
      padding: 1rem !important
    }

    html:not([dir=rtl]) .pt-xxl-3,
    html:not([dir=rtl]) .py-xxl-3 {
      padding-top: 1rem !important
    }

    html:not([dir=rtl]) .pr-xxl-3,
    html:not([dir=rtl]) .px-xxl-3 {
      padding-right: 1rem !important
    }

    html:not([dir=rtl]) .pb-xxl-3,
    html:not([dir=rtl]) .py-xxl-3 {
      padding-bottom: 1rem !important
    }

    html:not([dir=rtl]) .pl-xxl-3,
    html:not([dir=rtl]) .px-xxl-3 {
      padding-left: 1rem !important
    }

    html:not([dir=rtl]) .p-xxl-4 {
      padding: 1.5rem !important
    }

    html:not([dir=rtl]) .pt-xxl-4,
    html:not([dir=rtl]) .py-xxl-4 {
      padding-top: 1.5rem !important
    }

    html:not([dir=rtl]) .pr-xxl-4,
    html:not([dir=rtl]) .px-xxl-4 {
      padding-right: 1.5rem !important
    }

    html:not([dir=rtl]) .pb-xxl-4,
    html:not([dir=rtl]) .py-xxl-4 {
      padding-bottom: 1.5rem !important
    }

    html:not([dir=rtl]) .pl-xxl-4,
    html:not([dir=rtl]) .px-xxl-4 {
      padding-left: 1.5rem !important
    }

    html:not([dir=rtl]) .p-xxl-5 {
      padding: 3rem !important
    }

    html:not([dir=rtl]) .pt-xxl-5,
    html:not([dir=rtl]) .py-xxl-5 {
      padding-top: 3rem !important
    }

    html:not([dir=rtl]) .pr-xxl-5,
    html:not([dir=rtl]) .px-xxl-5 {
      padding-right: 3rem !important
    }

    html:not([dir=rtl]) .pb-xxl-5,
    html:not([dir=rtl]) .py-xxl-5 {
      padding-bottom: 3rem !important
    }

    html:not([dir=rtl]) .pl-xxl-5,
    html:not([dir=rtl]) .px-xxl-5 {
      padding-left: 3rem !important
    }
  }
}

.table-system {
  .p-datatable-thead>tr>th, .p-treetable-thead>tr>th {
    background: $table-border-head-bg !important;
    border-color: #a4a4a4;
    color: #464646 !important;
    font-size: 0.64rem;
    font-family: $font-system;
    text-transform: uppercase;
    padding: 0.3rem 0.5rem !important;
    font-weight: bold;
    .text-overflow-dynamic-ellipsis{
      max-height: 0.75rem;
    }
    .ui-state-highlight {
      background: $table-border-head-bg !important;
      color: #464646 !important;
      border:none !important;
    }
    .p-sortable-column{
      background-color: $table-border-head-bg !important;
      color: #464646 !important;
    }
    .p-sortable-column:focus{
      box-shadow:none;
    }
    p-sorticon {
      i{
        color: #007ad9 !important;
        font-size: 0.64rem;
      }
    }
    .type-p-mult {
      margin-top: 0.25em;
      display: block;

      .p-multiselect {
        .p-multiselect-label {
          font-size: 0.7rem;
          padding: 0.25rem;
          display: block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
        }

        .p-multiselect-trigger {
          .fa {
            margin-top: 0.8em;
          }
        }
      }
    }
    .text-overflow-dynamic-ellipsis {
      overflow-y: hidden !important;
    }
  }

  .p-datatable-tfoot>tr>td,
  .p-datatable-tbody>tr>td,
  .p-treetable-tbody>tr>td{
    height: 25px !important;
    font-size: 0.65rem;
    padding: 0.3rem 0.5rem !important;

    .p-treetable-toggler {
      width: 10px !important;
      height: 10px !important;
    }
  }
  .p-datatable-tbody>tr{
    td .action-fade {
      position: absolute;
      right: -30px;
      transition: 0.4s;
      transition-timing-function: ease-in-out;
      opacity: 0;
      border: 1px solid #444;
      border-radius: 3px;
      padding: 4px 6px;
      color: #444;
      margin-top: -2px;
    }

    &:hover {
      td .action-fade {
        line-height: 0;
        right: 5px;
        opacity: 1;

        &:hover {
          color: #999;
          border-color: #999;
        }
      }

      background: $table-td-hover;
      font-weight: bold;
    }
  }
  .p-paginator-rpp-options{
    width: 5rem;
  }
  .p-datatable-scrollable-header-box,
  .p-datatable-scrollable-footer-box {
    background: #d6d6d6 !important;
  }
  .p-datatable-scrollable-wrapper{
    .p-paginator-bottom {
      margin-right: 10px;
    }
  }
  .p-paginator {
    .p-paginator-pages .p-paginator-page{
      min-width: 1.7rem;
      height: 1.7rem;
      border-radius: 4px;
    }
    .p-dropdown{
      height: 1.7rem;
      .p-dropdown-trigger-icon{
        font-size: 0.7rem;
      }
      .p-dropdown-label{
        text-align: center;
        padding: 4px 0;
      }
    }
  }
}

/* TOOLBAR NOVO: ALEXANDRE*/
.btnCustom {
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: .3rem;
  padding: .25rem .6rem;

  background-color: transparent;

  margin-right: .625rem;
  cursor: pointer;
  font-size: .7rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #cccccc !important;
  div{
   margin: 0  0 0 .625rem;
  }
  i,span {
    margin: 0 ;
    padding: 0;
    font-size: 1.125rem;
  }

  &:disabled {
    &:hover {
      background-color: transparent !important;
      color: #cccccc !important;
    }
  }
}

.btnCustom-default{
  @extend .btnCustom;
  &:hover {
    background-color: #F2F2F2;
    color: #555 !important;
  }
}

.btnCustom-green{
  @extend .btnCustom;
  &:hover {
    background-color: #00BFA5;
    color: #F2F2F2 !important;
  }
}

.btnCustom-red{
  @extend .btnCustom;
  &:hover {
    background-color: hsl(340, 90%, 38%);
    color: #F2F2F2 !important;
  }
}

.tableHeaderCheckbox {
  width: 45px;
  text-align: center !important;
  vertical-align: text-bottom;
}

.tableCheckbox {
  width: 45px;
  text-align: center !important;
  padding: 0px !important;
}

.file-uploader.p-fileupload {

  .p-fileupload-buttonbar {
    background-color: transparent;
    padding: 5px 0px;
    margin-bottom: 15px;
    border: none;
    border-bottom: 1px solid #33333350;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    position: relative;

    .btn-closeHanled {
      position: absolute;
      right: 180px;
    }

    h3 {
      color: #474e52;
      font-weight: 700;
      font-size: 13px;
      text-transform: uppercase;
      margin: 0;
      line-height: 0;
    }

    .p-button {
      width: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: .3rem;
      padding: .25rem .6rem;
      background-color: transparent;
      margin-right: .625rem;
      cursor: pointer;
      font-size: .7rem;
      text-transform: uppercase;
      font-weight: bold;
      color: #cccccc !important;

      .p-button-label {
        font-size: .7rem;
      }

      .p-button-icon-left {
        margin-right: 0;
        font-size: 0.8rem;
        line-height: 2;
      }

      &:hover {
        background-color: #00BFA5;
        color: #F2F2F2 !important;
      }
    }
  }

  .p-button:focus {
    box-shadow: none;
  }

  .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
    box-shadow: none;
  }

  .p-fileupload-content {
    display: flex;
    height: auto;
    flex-direction: column-reverse;
    border-style: dashed;
    transition: 200ms ease-in-out;

    .p-progressbar {
      display: none;
    }

    .drag-text {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $main-grey;

      h2 {
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }

  .p-fileupload-files {

    .file {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $main-black;
      padding: 10px 0px;
      border-top: 1px dashed $main-grey;

      p {
        font-size: 11px;
        margin: 0;
        text-transform: lowercase;
      }

      .btn-remove {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0;
        margin: 0;

        span {
          font-size: 18px;
          color: $main-grey;
        }

        &:hover {
          span {
            color: $main-pink;
          }
        }
      }
    }
  }

  .p-message .p-message-wrapper {
    font-size: 12px;
    text-transform: lowercase;
    padding: .5rem 1rem;
  }

}

// table-system 2 ->

.border-table {
  border: none !important;
  border-top: .5px solid $border-table !important;
  border-bottom: .5px solid $border-table !important;
}

.table-system2 {

  .p-datatable-thead > tr > th {
    @extend .border-table;
    background: $header-table !important;
    color: $main-black !important;
    font-size: 11px;
    font-family: $font-system;
    text-transform: uppercase;
    font-weight: bold;

    p-sorticon {
      i{
        color: $accent-color !important;
        font-size: 11px;
      }
    }

    .type-p-mult {
      margin-top: 0.25em;
      display: block;

      .p-multiselect {
        .p-multiselect-label {
          font-size: 0.7rem;
          padding: 0.25rem;
          display: block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
        }

        .p-multiselect-trigger .fa {
          margin-top: 0.8em;
        }
      }
    }
  }

  .p-datatable-tbody > tr{
    &:hover {
      background: $content-hover-table;
      font-weight: bold;
    }
  }

  .p-datatable-tbody > tr > td, .p-datatable-tfoot > tr > td {
    @extend .border-table;
    height: 25px !important;
    font-size: 0.65rem;
  }

  .p-checkbox .p-checkbox-box {
    width: 15px;
    height: 15px;
    margin: auto;

    .p-checkbox-icon {
      font-size: 9px;
    }
  }

  .p-datatable-reorderablerow-handle, [pReorderableColumn] {
    font-size: 18px;
    opacity: .5;
  }

  .p-paginator-rpp-options{
    width: 3rem;
  }

  .p-datatable-scrollable-wrapper{
    .p-paginator-bottom {
      margin-right: 10px;
    }
  }

  .p-paginator-bottom {
    @extend .border-table;
    background: $header-table !important;
    padding: 4px 0px;
  }

  .p-paginator {
    .p-paginator-pages .p-paginator-page.p-highlight {
      background: $accent-color;
      color: $main-white;
    }

    .p-paginator-pages .p-paginator-page{
      min-width: 1.5rem;
      height: 1.5rem;
      margin: 0px 2px;
      border-radius: 50%;
      font-size: 11px;
    }

    .p-paginator-last, .p-paginator-next, .p-paginator-prev, .p-paginator-first {
      min-width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      font-size: 11px;
    }

    .p-dropdown{
      height: 1.7rem;

      .p-dropdown-trigger-icon{
        font-size: 0.7rem;
      }

      .p-dropdown-label{
        text-align: center;
        padding: 3px 0;
      }

      .p-dropdown-trigger, .p-dropdown .p-multiselect-trigger {
        color: $accent-color;
        border-color: transparent !important;
      }

      &:not(.p-disabled) {
        &:hover, &.p-focus {
          border-color: $accent-color;
        }
      }
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      background: $accent-color !important;
    }
  }

  .p-link:focus {
    box-shadow: 0 0 0 0.2rem $accent-opacity-05;
  }
}

::ng-deep.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 $accent-color;
}

::ng-deep.alert-primary {
  color: $accent-color;
  background-color: $content-hover-table;
}

::ng-deep.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: $accent-color;
}

::ng-deep.p-radiobutton .p-radiobutton-box.p-highlight, ::ng-deep.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: $accent-color;
  border-color: $accent-color;
}

.p-field-checkbox {
  display: flex;
  align-items: center;
}

::ng-deep.mat-menu-panel .mat-menu-item:hover:not([disabled]) {
  background: rgba(85, 0, 189, 0.05);
  color: #8b95a5;
}

label {
  display: initial !important;
}

::ng-deep.mat-slide-toggle-label {
  display: flex !important;
}