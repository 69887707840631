@import './header.scss';
// @import './src/app/shared/styles/bootstrap.scss';
@import './src/app/shared/styles/colors-system.scss';
@import './src/app/shared/styles/font-system.scss';
@import '~primeicons/primeicons.css';
@import './src/app/shared/styles/centralweb.scss';
@import './src/app/shared/styles/animations-system.scss';
@import './src/app/shared/styles/default-system.scss';

$progress-width-height: 90px;
$loading-top: calc(50% - 45px);
$loading-left: calc(50% - 45px);
$loading-top-message: calc(50% - 45px);
$loading-left-message: calc(50% - 95px);
$sidenav-width: 280px;

html, body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
  height: 100%;
  width: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: $font-system;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


div.shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999998;
  cursor: not-allowed;
}
div.shadow.offline {
  background-color: #333;
  opacity: 1;
}

div.loading {
  display: block;
  position: absolute;
  top: $loading-top;
  left: $loading-left;
  z-index: 999999;
  cursor: not-allowed;
  text-align: center;

  span {
    display: block;
    margin-top: 10px;
  }
}
div.loading.message {
  top: $loading-top-message;
  left: $loading-left-message;
}
div.loading .mat-progress-circle {
  width: $progress-width-height;
  height: $progress-width-height;
}
div.loading.message > div {
  margin-left: 48px !important;
}
div.loading.message > span {
  font-family: $font-system;
  font-size: 13px;
  color: #fff;
}

