// NOVO SISTEMA DE CORES OCTALINK

$font-sizeContent: 13px;
$font-sizeLabel: 11px;
$font-sizeSubTitle: 14px;
$font-sizeMinTitle: 16px;
$font-sizeMaxTitle: 18px;
$font-sizeTitle: 20px;

$accent-color: rgb(85, 0, 189);
$main-white: rgb(247, 242, 246);
$main-black: rgb(33, 33, 33);
$main-pink: rgb(232, 43, 123);
//$main-yellow: rgb(254, 198, 1);
$main-yellow: rgb(250, 182, 19);
$main-dark-purple: rgb(36, 4, 45);
$main-soft-blue: rgb(39, 232, 188);
$main-grey: rgb(204, 204, 204);

$is-white: rgb(255, 255, 255);

$accent-color-hover: rgb(63, 6, 133);
$white-opacity-09: rgba(255, 255, 255, 0.9);
$black-opacity-05: rgba(33, 33, 33, 0.5);
$black-opacity-08: rgba(33, 33, 33, 0.8);
$black-opacity-005: rgba(33, 33, 33, 0.05);
$black-opacity-015: rgba(33, 33, 33, 0.15);
$accent-opacity-05: rgba(85, 0, 189, 0.5);

$header-table: rgb(248, 249, 250);
$content-hover-table: rgba(85, 0, 189, 0.1);
$border-table: rgb(243, 243, 243);

$border-radius: .3em;

$box-shadow: 0  5px 10px rgba(154,160,185,0.05), 0 15px 40px rgba(166,173,201,0.2);

$time-animation: 0.3s;

@mixin d-flex($direction, $align, $justify, $wrap) {
  display: flex;
  flex-direction: #{$direction};
  justify-content: #{$justify};
  align-items: #{$align};
  flex-wrap: #{$wrap};
}


