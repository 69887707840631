/*TITLES*/
$sub-title-color: #474e52;
$sub-title-border-color: #cccccc;
$sub-title-icon-color: #0091ea;
$title-page-color: #474e52 !important;
$sub-title-page-color: #646565;
$title-content: '';
$sub-title-content: '';


/*BUTTONS*/

/*Btn Link*/
$btn-link-color: #666666;
$btn-link-color-hover: #333333;
$btn-link-color-excel: #00bfa5;

/*Btn Primary*/
$btn-primary-bg: #00bfa5;
$btn-primary-border: #00bfa5;
$btn-primary-bg-hover: #00a18b;
$btn-primary-border-hover: #00a18b;
$btn-primary-bg-focus: #00a18b !important;
$btn-primary-border-focus: #00a18b !important;

/*Btn Secondary*/
$btn-secondary-bg: #c9c9c9;
$btn-secondary-border: #c9c9c9;
$btn-secondary-bg-hover: #9c9a9a;
$btn-secondary-border-hover: #9c9a9a;
$btn-secondary-bg-focus: #9c9a9a !important;
$btn-secondary-border-focus: #9c9a9a !important;

/*Btn Info*/
$btn-info-bg: #f2f2f2;
$btn-info-color:#333333;
$btn-info-border: #cccccc;
$btn-info-bg-hover: #f2f2f2;
$btn-info-color-hover:#0091EA;
$btn-info-border-hover: #cccccc;
$btn-info-bg-focus: #f2f2f2 !important;
$btn-info-border-focus: #cccccc !important;

/*Btn Danger*/
$btn-danger-bg: #c22333;
$btn-danger-border: #c22333;
$btn-danger-bg-hover: #b3202e;
$btn-danger-border-hover: #b3202e;
$btn-danger-bg-focus: #b3202e !important;
$btn-danger-border-focus: #b3202e !important;

/*Btn Outline Primary*/
$btn-outline-primary-color: #0091EA;

/*GRID BUTTONS MÓDULO*/
$grid-icon-color:#4d5259;
$grid-icon-color-hover:#00BFA5;

/*CLOSE CARD*/
$color-close-card:#999999;
$color-close-card-hover:#dd2c00;

/*TABLES*/
$table-td-hover: #c9eaff;
$table-sortable: #00bfa5;
$table-state-highlight-bg: #4e6691;
$table-state-highlight-bg-hover: #4e6691;
$table-border-head-bg: #efefef;

/*TREETABLE*/
$table-tree-head-bg: #efefef;
$table-tree-toggler: #00bfa5;

/*PAGINATOR*/
$paginator-bg: #00bfa5;
$paginator-color: #ffffff;
$paginator-border: #00bfa5;



/*FORMS*/

/*Prime Select*/
$ui-dropdown-border-hover: #00bfa5;
$ui-dropdown-trigger-bg: #ffffff;
$ui-dropdown-trigger-color: #00bfa5;
$ui-state-highlight-bg: #00bfa5;
$ui-state-highlight-border: #00bfa5;
$ui-dropdown-items-bg: #ffffff;

/*Prime Mult-Select*/
$ui-mult-border-hover: #00bfa5;
$ui-mult-trigger-bg: #ffffff;
$ui-mult-trigger-color: #00bfa5;
$ui-mult-highlight-bg: #eeeeee;
$ui-mult-highlight-border: #d6d6d6;
$ui-mult-highlight-color: #000;
$ui-mult-items-bg: #ffffff;
$ui-mult-acctive-check: #00bfa5;
$ui-mult-acctive-check-border: #00bfa5;

/*Bootstrap*/
$form-control-border-hover: #00bfa5;
$form-control-border-focus: #00bfa5;

/*Label*/
$label: #555;

/*Prime*/
$ui-inputtext-border-hover: #00bfa5;

/*Calendar*/
$ui-state-active: #00bfa5;

/*Mat Slide Toggle*/
$mat-slide-toggle-thumb: #00bfa5;
$mat-slide-toggle-bar: rgba(0, 191, 165, .5);

/*CheckBox*/
$mat-checkbox-background: #00bfa5;

/*Radio*/
$mat-radio-background: #00bfa5;

/*CARDS*/
$vl-total: #00bfa5;

/*ACCORDION*/
$accordion-icon: #00bfa5;

/*FILTER MENU*/
$filter-menu-color: #666666;
$filter-menu-hover-active: rgb(85, 0, 189);

/*NAVBAR*/
$nav-custom-bg: #ececec;
$nav-custom-border: #dddddd;
$nav-link-icon-hover: #1155bd;
$dropdown-item-bg-hover: #00bfa5;
$dropdown-item-color-hover: #ffffff !important;

/*LOGIN*/
// $bg-login: #1155bd;
$bg-login: #24032c;
$btn-border: #ffffff;
$btn-color: #ffffff;
$btn-active-hover: #ffffff;
$btn-active-color: #1155bd;
$btn-disabled-focus-hover: #ffffff;
$btn-more-color: #1155bd;
$btn-more-color-hover: #1155bd;
$btn-u: #ffffff;
$btn-back: #ffffff;
$link-login: #ffffff;
$link-login-hover: #ffffff;
$back-svg: #ffffff;
$check-svg: #00bfa5;
;
$animation-line-before: #ffffff;
$title-login-border: transparent;
$title-login-color: #1155bd;

/*SIDEBAR*/
$sidenav-color: rgb(36, 3, 44);
$side-border: rgb(247, 242, 246);
$link-side: rgb(247, 242, 246);
$border-side: rgb(247, 242, 246);
$icon-side: rgb(247, 242, 246);
$icon-side-hover: rgb(247, 242, 246);

/*MAIN*/
$bg-main: #F2F5F8;

/*TOOLBAR HEADER FIXED*/
$toolbar-background-color:#f8fafb;
$toolbar-text: #5c6064;
$toolbar-drop-bg: #F8FAFB;
$toolbar-drop-text: #222222;
$toolbar-drop-bg-hover: #ededed;
$toolbar-drop-text-hover: #212121;

/*DROPDOWN PRIME*/
$dropdown-bg: #ffffff;
$dropdown-text: #222222;

/*FORM BG*/
$form-bg: #ffffff;
$form-text: #222222;
