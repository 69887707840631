@import './src/app/shared/styles/font-system.scss';

.margin-linnks {
  margin-left: 1%;
  margin-top: 2%;
  margin-right: 1%;
  margin-bottom: 2%;
}

::ng-deep.card-linnks{
  border-radius: 0px;
  // height: calc(100% - 2px);
  border: 0px;
  border-style: none;
  box-shadow: 0 0px 0px 0px rgba(0,0,0,.2), 0 0px 0px 0 rgba(0,0,0,.14), 0 0px 0px 0 rgba(0,0,0,0) !important;
   .ui-messages{
    position: fixed !important;
    z-index: 9999 !important;
    right: 80px !important;
    bottom: 50px !important;
    width: 100% !important;
    max-width: 550px !important;
    margin: 0 !important;
    padding: 15px 25px 15px 15px !important;
    box-shadow: 0 0 50px rgba(0,0,0,0.15) !important;
    border: none !important;
    box-sizing: border-box !important;

     .ui-messages-summary,
     .ui-messages-detail{
      font-size : 14px;
    }

     .ui-messages-close{
      font-size: 15px;
      top: 10px;
      right: 10px;
    }
  }
}

.h2-linnks{
  font-size: 16px !important;
  font-weight: 300;
  font-family: $font-system;
  color:black;
}

.h3-linnks{
  font-size: 14px !important;
  font-weight: 400;
  font-family: $font-system;
  color:black;
}

.h4-linnks{
  font-size: 13px !important;
  font-weight: 400;
  font-family: $font-system;
  color:black;
}

:host ::ng-deep .ui-picklist-item{
  font-weight: normal;
  font-family: $font-system;
  font-size: 11px !important;
  width: 300px;
}

 ::ng-deep .ui-picklist-source-controls{
  width: 30px;
  align-content: center;
  .ui-widget-header{
    .ui-button, .ui-widget-content .ui-button, .ui-widget.ui-button, .ui-button {
    border: 1px solid #9B9B9B;
    color: black;
    background-color: #9B9B9B;
    -webkit-transition: background-color .2s;
    transition: background-color .2s;
     }
  }
   .ui-widget-header .ui-button:enabled:hover, .ui-widget-header .ui-button:focus, .ui-widget-content .ui-button:enabled:hover, .ui-widget-content .ui-button:focus, .ui-widget.ui-button:enabled:hover, .ui-widget.ui-button:focus, .ui-button:enabled:hover, .ui-button:focus {
    border: 1px solid #9B9B9B;
    background-color: #9B9B9B;
    outline: 0 none;
    color: #ffffff;
  }
}
 ::ng-deep .ui-picklist-target-controls{
  width: 30px;
  align-content: center;
   .ui-widget-header .ui-button, .ui-widget-content .ui-button, .ui-widget.ui-button, .ui-button{
    border: 1px solid #f0f0f0;
    color: black;
    background: #f0f0f0;
    width: 30px;
  }
}
::ng-deep .ui-picklist-buttons{
  width: 30px;
  align-content: center;
     .ui-widget-header .ui-button, .ui-widget-content .ui-button, .ui-widget.ui-button, .ui-button{
      border: 1px solid #f0f0f0;
      color: black;
      background: #f0f0f0;
      width: 30px;
  }
}
.mat-card-linnks{
  // height: calc(100%);
  font-weight: normal;
  font-family: $font-system;
  font-size: 0.8em !important;
}

.modal-linnks{
    display: table;
    //height: 100%;
    //width: 100%;
    pointer-events: none;
}

::ng-deep .ui-dropdown-panel .ui-dropdown-filter-container {
  width: 98%
}

