@import '~@angular/material/_theming';
@import './app/shared/styles/font-system.scss';
@include mat-core();

$primary: mat-palette($mat-blue, 800);
$accent: mat-palette($mat-blue, 900);
$warn: mat-palette($mat-red);

$theme: mat-light-theme($primary, $accent, $warn);

@include angular-material-theme($theme);


$progress-width-height: 90px;
$loading-top: calc(50% - 45px);
$loading-left: calc(50% - 45px);
$loading-top-message: calc(50% - 45px);
$loading-left-message: calc(50% - 95px);

$menu-icon-color: #243867;
$menu-select-icon-color: #1565c0;

$toolbar-button-background-color: #0091EA;
$title-color: #ffffff;
$title-font-size: 25px;

@media print {
  * {
    margin: 0;
    padding: 0;
  }

  html, body {
    width: 100%;
  }

  #Footer, #Header, .screen, .mat-select, mat-option, div.cdk-overlay-pane, div.mat-select-panel, div.mat-select-content {
    display: none;
    font-family: $font-system;
  }

  .print {
    display: block !important;
  }

  .no-print	{
    display: none;
  }

  .page-break	{
    display: block;
    page-break-before: always;
  }

  #print_area {
    width: 100%;

    div.datatable table {
      width: 100%;
    }
    div.datatable table thead tr.filters {
      display: none;
    }
  }
}

@media display {
  #print_area, #print_area * {
    display: none;
  }

  .page-break	{
    display: none;
  }
}

// @import './legacy.scss';
